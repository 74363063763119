import React, { useReducer } from "react";
import { App } from "./App";
import { useHistory } from "react-router-dom";
import { rootReducer, state as initialState } from "./state/root.reducer";
import { DispatchContext } from "./context";

import { setupAxiosInterceptors } from "./utils/setup-axios-interceptors";

export const Bootstrap: React.FC = (): JSX.Element => {
    const history = useHistory();
    const [state, dispatch] = useReducer(rootReducer, initialState);

    // retry failing requests with refreshed JWT
    setupAxiosInterceptors(dispatch, history);

    return (
        <DispatchContext.Provider value={{ dispatch }}>
            <App state={state} dispatch={dispatch} />
        </DispatchContext.Provider>
    );
};
