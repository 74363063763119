import React from "react";
import "./styles.css";

type Props = {};

export const Modal: React.FC<Props> = ({ children }): JSX.Element => {
    return (
        <div className="backdrop p-d-flex p-ai-center p-jc-center">
            <div className="modal p-col-10 p-md-6 p-lg-4 p-p-sm-4">{children}</div>
        </div>
    );
};
