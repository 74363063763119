import React, { useEffect } from "react";
import { Toast, ToastMessage } from "primereact/toast";
import { UiActions, UiActionTypes } from "../state/ui.reducer";

type Props = {
    toasts: ToastMessage[];
    dispatch: (action: UiActionTypes) => void;
};

export const ToastArea: React.FC<Props> = ({ toasts = [], dispatch }) => {
    let toast: Toast | null = null;

    useEffect(() => {
        toasts.length > 0 && toast && toast.show(toasts);
    }, [toasts, toast]);

    return (
        <Toast
            ref={(el) => {
                toast = el;
            }}
            onRemove={() => dispatch({ type: UiActions.CLEAR_TOAST_MESAGES })}
        />
    );
};
