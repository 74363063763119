import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useRequest } from "./use-request";
import { MessageProps } from "primereact/message";
import { DispatchContext } from "../context/dispatch.context";
import { UserActions } from "../state/user.reducer";
import { AuthActions, AuthState, LoginState } from "../state/auth.reducer";
import { useLocalStorage } from "./use-localstorage";
import { User } from "../entities/user.entity";
import { UiActions } from "../state/ui.reducer";
import { isConnectionFailure } from "./is-connection-failure";
import { makeToast } from "../utils/make-toast";

type ReturnType = {
    getUser: (id: string) => void;
    login: (email: string, password: string) => void;
    signup: (email: string, password: string) => Promise<MessageProps | undefined>;
    logout: () => void;
    requestPasswordReset: (email: string) => void;
};

export type Response<T> = { status: number; data: T };

export const useUser = (): ReturnType => {
    const history = useHistory();
    const { dispatch } = useContext(DispatchContext);
    const [, setLocalAuth, removeLocalAuth] = useLocalStorage<AuthState>("auth", {
        email: undefined,
        id: undefined,
        token: undefined,
    });

    const { request } = useRequest();

    const getUser = async (id: string): Promise<void> => {
        const response = await request<User>({ url: `/users/me` });

        if (isConnectionFailure<User>(response, dispatch)) {
            return;
        }

        if (response.status >= 400) {
            dispatch({
                type: UiActions.ADD_TOAST_MESSAGE,
                payload: makeToast({
                    severity: "error",
                    summary: "Fehler",
                    detail: "Userinformationen konnten nicht geladen werden",
                }),
            });
        } else {
            dispatch({ type: UserActions.SET_USER, payload: response.data });
        }
    };

    const login = async (email: string, password: string): Promise<void> => {
        const response = await request<LoginState>({ url: `/auth/login`, method: "POST", data: { email, password } });

        if (isConnectionFailure<LoginState>(response, dispatch)) {
            return;
        }

        if (response.status >= 400) {
            dispatch({
                type: UiActions.ADD_TOAST_MESSAGE,
                payload: makeToast({
                    severity: "error",
                    summary: "Login fehlgeschlagen",
                    detail: "E-Mail-Adresse oder Passwort stimmen nicht",
                }),
            });
        } else {
            setLocalAuth(response.data.auth);
            dispatch({ type: AuthActions.LOGIN_USER, payload: response.data });
            // dispatch({ type: UiActions.ADD_TOAST_MESSAGE, payload: { severity: "success", summary: "Login", detail: `Erfolgreich eingeloggt`, life: 5000 } });
            history.push("/");
        }
    };

    const signup = async (email: string, password: string): Promise<MessageProps | undefined> => {
        const response = await request<AuthState>({ url: `/auth/signup`, method: "POST", data: { email, password } });

        if (isConnectionFailure<AuthState>(response, dispatch)) {
            return;
        }

        if (response.status >= 400) {
            return {
                severity: "error",
                text: "Ihr Account konnte nicht erstellt werden",
            };
        } else {
            dispatch({ type: AuthActions.USER_SIGNUP_SUCCESSFULL, payload: response.data });
            return {
                severity: "success",
                text: `Bitte aktivieren Sie Ihren Account über den Link, den Sie eben per E-Mail an Ihre Adresse ${email} erhalten haben. Bitte prüfen Sie ggf. auch Ihren Spam-Ordner.`,
            };
        }
    };

    const logout = async (): Promise<void> => {
        await request({ url: `/auth/logout` });
        removeLocalAuth();
        dispatch({ type: AuthActions.LOGOUT_USER });
    };

    const requestPasswordReset = async (email: string): Promise<void> => {
        const response = await request<undefined>({ url: `/auth/new-password`, method: "POST", data: { email } });

        if (isConnectionFailure<undefined>(response, dispatch)) {
            return;
        }

        if (response.status >= 400) {
            dispatch({
                type: UiActions.ADD_TOAST_MESSAGE,
                payload: makeToast({
                    severity: "error",
                    summary: "Passwort zurücksetzen",
                    detail: "Etwas ist schief gelaufen",
                }),
            });
        } else {
            dispatch({
                type: UiActions.ADD_TOAST_MESSAGE,
                payload: makeToast({
                    severity: "success",
                    summary: "Passwort zurücksetzen",
                    detail: `Ein Link zum Zurücksetzen Ihres Passworts ist auf dem Weg zu Ihrer E-Mail-Adresse ${email}`,
                }),
            });
        }
    };

    return { getUser, login, signup, logout, requestPasswordReset };
};
