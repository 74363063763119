import { Dispatch } from "react";
import { UiActions, UiActionTypes } from "../state/ui.reducer";
import { makeToast } from "../utils/make-toast";
import { Response } from "./useUser.repository";

export const isConnectionFailure = <T>(response: Response<T>, dispatch: Dispatch<UiActionTypes>): boolean => {
    if (response.status === undefined && response.data === undefined) {
        dispatchFailureAction(dispatch);
        return true;
    }
    return false;
};

function dispatchFailureAction(dispatch: Dispatch<UiActionTypes>) {
    dispatch({
        type: UiActions.ADD_TOAST_MESSAGE,
        payload: makeToast({
            severity: "error",
            summary: "Login fehlgeschlagen",
            detail: "Es konnte keine Verbindung zum Server hergestellt werden",
        }),
    });
}
