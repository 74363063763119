import { Organisation } from "../entities/organisation.entity";
import { OrganisationsActions } from "./organisation.reducer";

export enum ActiveOrganisationActions {
    SET_ACTIVE_ORGANISATION = "SET_ACTIVE_ORGANISATION",
}

export type ActiveOrganisationActionTypes =
    | { type: ActiveOrganisationActions.SET_ACTIVE_ORGANISATION; payload: Organisation }
    | { type: OrganisationsActions.ORGANISATION_CREATED; payload: Organisation }
    | { type: OrganisationsActions.ALL_ORGANISATIONS_RECEIVED; payload: Organisation[] }
    | { type: OrganisationsActions.ORGANISATION_DELETED; payload: string };

export const activeOrganisationReducer = (
    state: Organisation | undefined = undefined,
    action: ActiveOrganisationActionTypes
) => {
    switch (action.type) {
        case OrganisationsActions.ALL_ORGANISATIONS_RECEIVED:
            // wenn noch keine activeOrganisation gesetzt ist, der User aber mehrere Orgas hat, dann nichts tun
            // wenn aber keine activeOrganisation gesetzt ist und nur eine orga zurück kommt, dann setz die als aktiv
            return !state && action.payload.length > 1 ? state : action.payload[0];

        case ActiveOrganisationActions.SET_ACTIVE_ORGANISATION:
            return action.payload;

        case OrganisationsActions.ORGANISATION_CREATED:
            return action.payload;

        case OrganisationsActions.ORGANISATION_DELETED:
            return undefined;

        default:
            return state;
    }
};
