import React, { MouseEvent } from "react";
import { Button } from "primereact/button";
import { Image } from "../../entities/image.entity";
import { FileUpload } from "./file-upload";
import "./styles.css";

const { REACT_APP_API_URL: API_URL } = process.env;

type Props = {
    id: string;
    images: Image[] | Image | null | undefined;
    disabled?: boolean;
    chooseLabel?: string;
    multiple?: boolean;
    fieldName?: string;
    setImages: (imgs: Image[]) => void;
};

export const UploadArea: React.FC<Props> = ({
    id,
    images = [],
    chooseLabel,
    disabled,
    fieldName,
    multiple = false,
    setImages,
}) => {
    function removeImage(e: MouseEvent<HTMLButtonElement>, id: Image["_id"]) {
        e.preventDefault();

        const imageArray = images && !Array.isArray(images) ? [images] : images;
        const imgs = (imageArray || []).filter((image: Image) => image._id !== id);
        setImages(imgs);
    }

    function renderImages() {
        if (!images) {
            return null;
        }

        const imageArray = !Array.isArray(images) ? [images] : images;
        return imageArray.length > 0 ? (
            <ul className="image-upload-area">
                {imageArray.map((img) => (
                    <li key={img._id} className="image__item">
                        <Button
                            icon="pi pi-times"
                            className="p-button-rounded p-button-danger image__remove-x"
                            onClick={(e: MouseEvent<HTMLButtonElement>) => removeImage(e, img._id)}
                        />

                        <img className="image" alt={img.caption} src={`${API_URL}/${img.renditions?.square?.path}`} />
                    </li>
                ))}
            </ul>
        ) : null;
    }

    return (
        <>
            <FileUpload
                id={id}
                setImages={setImages}
                multiple={multiple}
                fieldName={fieldName}
                disabled={!id}
                chooseLabel={chooseLabel}
            />
            {renderImages()}
        </>
    );
};

export default UploadArea;
