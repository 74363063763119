import { OfferDto } from "../../entities/offer.dto";

export let offerBlueprint: OfferDto = {
    name: "",
    description: "",
    foodTypes: [], // vegan, glutenfrei, ...
    price: undefined,
    date: undefined,
    cuisines: [],
    image: undefined,
    store: "",
    organisation: "",
    location: {
        coordinates: ["", ""],
    },
    offerTimes: {
        noon: true,
        evening: false,
    },
};
