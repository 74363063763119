import { Organisation } from "../entities/organisation.entity";
import { Role } from "../entities/role.type";
import { User } from "../entities/user.entity";

export function isAllowed(user: User, organisationId?: Organisation["_id"]): boolean {
    if (user.isAdmin) {
        return true;
    }

    if (!organisationId) {
        return false;
    }

    const role = user.roles.find((role: Role) => role.organisation === organisationId);

    return role && ["owner"].includes(role.role) ? true : false;
}
