import React, { MouseEvent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { StoreForm } from "./store.form";
import { useStore } from "../../hooks/useStore.repository";
import { Store } from "../../entities/store.entity";
import { StoreDtoWithImages } from "../../entities/store.dto";
import { useQuery } from "../../hooks/use-query";
import { Authorized } from "../../access-control/authorized";

const { REACT_APP_APP_URL: APP_URL } = process.env;

export const StoreEdit: React.FC = (): JSX.Element | null => {
    const [store, setStore] = useState<Store>();
    const history = useHistory();
    const { organisationId, storeId } = useParams<{ organisationId: string; storeId: string }>();
    const query = useQuery();
    const { get, update, remove } = useStore(organisationId, storeId);

    const returnTo = query.get("returnTo");

    useEffect(() => {
        (async () => {
            if (storeId) {
                const store = await get(storeId);
                if (store) {
                    setStore(store);
                }
            }
        })();
    }, [storeId]);

    async function kill(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        if (remove) {
            if (window.confirm(`Sicher, dass\n\n${store?.name}\n\ngelöscht werden soll?`)) {
                await remove();
                history.push("/settings/organisations");
            }
        }
    }

    async function handleSubmit(values: StoreDtoWithImages) {
        // const valuesWithImagesTransformedToIds =
        //     values.images && isType<Image[]>(values.images, "image")
        //         ? {
        //               ...values,
        //               images: values.images.map((image: Image) => image._id),
        //           }
        //         : values;
        await update(values, storeId!);

        history.push(returnTo ? returnTo : "/settings/organisations");
    }

    return store ? (
        <Authorized entityId={store?.organisation as string} message={<div>Zugriff nicht gestattet</div>}>
            <h1>
                Firma {storeId ? "bearbeiten" : "anlegen"}{" "}
                {storeId && (
                    <a href={`${APP_URL}/gastro/${store?.slug}`} target="_blank" rel="noopener noreferrer">
                        <i className="pi pi-external-link p-ml-1" style={{ fontSize: ".8em" }}></i>
                    </a>
                )}
            </h1>

            {store && (
                <StoreForm
                    organisationId={organisationId}
                    id={storeId}
                    data={store}
                    remove={kill}
                    handleSubmit={handleSubmit}
                    returnTo={returnTo}
                />
            )}
        </Authorized>
    ) : null;
};
