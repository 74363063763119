import { Organisation } from "../entities/organisation.entity";
import { Role, Roles } from "../entities/role.type";
import { User } from "../entities/user.entity";
import { AuthActions, LoginState } from "./auth.reducer";
import { OrganisationsActions } from "./organisation.reducer";

export enum UserActions {
    SET_USER = "SET_USER",
}

export type UserState = User | null;

export const initialUserState = null;

export type UserActionTypes =
    | { type: UserActions.SET_USER; payload: User }
    | { type: AuthActions.LOGIN_USER; payload: LoginState }
    | { type: AuthActions.LOGOUT_USER }
    | { type: OrganisationsActions.ORGANISATION_CREATED; payload: Organisation }
    | { type: OrganisationsActions.ORGANISATION_DELETED; payload: Organisation["_id"] };

export const userReducer = (state: UserState = initialUserState, action: UserActionTypes) => {
    switch (action.type) {
        case UserActions.SET_USER:
            return action.payload;

        case AuthActions.LOGIN_USER:
            return action.payload.user;

        case AuthActions.LOGOUT_USER:
            return initialUserState;

        case OrganisationsActions.ORGANISATION_CREATED:
            return state
                ? {
                      ...state,
                      roles: state?.roles
                          ? [...state.roles, { role: Roles.owner, organisation: action.payload._id }]
                          : [{ role: Roles.owner, organisation: action.payload._id }],
                  }
                : state;

        case OrganisationsActions.ORGANISATION_DELETED:
            return state
                ? {
                      ...state,
                      roles: state?.roles
                          ? state.roles.filter((role: Role) => role.organisation !== action.payload)
                          : [],
                  }
                : state;

        default:
            return state;
    }
};
