import React, { useContext } from "react";
import classNames from "classnames";
import { Header, Sidebar } from "../index";
import "./styles.css";

import { UiContext } from "../../context";
import { Link } from "react-router-dom";

type Props = {
    className?: string;
};

export const Layout: React.FC<Props> = ({ children, className }) => {
    const { sidebarVisible, isLoading } = useContext(UiContext);
    const classes = classNames("layout", className);

    return (
        <>
            <Header>
                <Link to="/" className="logo-container">
                    <div className="logo" />
                </Link>
            </Header>
            <Sidebar sidebarVisible={sidebarVisible} />
            <div className={classes}>
                <div className="layout__inner">{children}</div>
            </div>
            {isLoading && <i className="pi pi-spin pi-spinner" style={{ "fontSize": "2em" }}></i>}
        </>
    );
};
