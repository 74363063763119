import React from "react";
import { Route, Switch } from "react-router-dom";
import { OfferDetail } from "./offers-by-store.detail";

type Props = {
    mountPath?: string;
};

export const OfferRoutes: React.FC<Props> = ({ mountPath = "" }): JSX.Element => {
    return (
        <Route>
            <Switch>
                <Route path={`${mountPath}/store/:id/offers`}>
                    <OfferDetail />
                </Route>
            </Switch>
        </Route>
    );
};
