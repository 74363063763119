import React, { ChangeEvent, FormEvent, useContext, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { DataContext, DispatchContext } from "../../../context";
import { UiActions } from "../../../state/ui.reducer";
import { makeToast } from "../../../utils/make-toast";
import "./styles.css";

type Props = {};

export const Feedback: React.FC<Props> = (props): JSX.Element => {
    const history = useHistory();
    const { dispatch } = useContext(DispatchContext);
    const [message, setMessage] = useState<string>("");
    const { activeOrganisation } = useContext(DataContext);

    async function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();

        const { name = "", address = {}, phone = "" } = activeOrganisation || {};

        if (!message.length) {
            return;
        }

        try {
            const response = await axios({
                url: `/feedback`,
                method: "POST",
                data: { message, organisation: { name, address, phone } },
            });
            setMessage("");
            if (response.status >= 400) {
                dispatch({
                    type: UiActions.ADD_TOAST_MESSAGE,
                    payload: makeToast({
                        summary: "Fehler",
                        detail: "Ihre Nachricht konnte nicht gesendet werden",
                    }),
                });
            } else {
                dispatch({
                    type: UiActions.ADD_TOAST_MESSAGE,
                    payload: makeToast({
                        severity: "success",
                        summary: "Danke für Ihr Feedback!",
                        detail: "Wir haben Ihre Nachricht erhalten.",
                        sticky: false,
                    }),
                });
                history.push("/");
            }
        } catch (error) {
            dispatch({
                type: UiActions.ADD_TOAST_MESSAGE,
                payload: makeToast({
                    summary: "Fehler",
                    detail: "Ihre Nachricht konnte nicht gesendet werden",
                }),
            });
        }
    }

    function handleChange(event: ChangeEvent<HTMLTextAreaElement>) {
        setMessage(event.target.value);
    }

    return (
        <form onSubmit={handleSubmit}>
            <h1>Ihr Feedback</h1>
            <p>Danke, dass Sie uns helfen, tagesessen.com zu verbessern!</p>
            <div className="p-fluid">
                <div className="p-field">
                    <label htmlFor="feedback-message">Ihre Nachricht</label>
                    <InputTextarea
                        type="text"
                        id="feedback-message"
                        name="feedback-message"
                        value={message}
                        onChange={handleChange}
                        rows={8}
                        autoResize
                    />
                </div>
            </div>
            <div className="p-field">
                <div className="p-d-flex p-jc-end">
                    <Button label="Abschicken" type="submit" />
                </div>
            </div>
        </form>
    );
};
