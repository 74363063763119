export enum LoadingActions {
    SET_IS_LOADING = "SET_IS_LOADING",
}

export type LoadingActionTypes = { type: LoadingActions; payload: boolean };

export type LoadingState = boolean;

export const initialLoadingState = false;

export const loadingReducer = (state: LoadingState, action: LoadingActionTypes): LoadingState => {
    return state;
};
