import React, { useContext } from "react";
import { Redirect } from "react-router-dom";

import { AuthContext } from "../../context/auth.context";

export const RequireAuth: React.FC = ({ children }): JSX.Element => {
    const { user } = useContext(AuthContext);

    return user?._id ? <>{children}</> : <Redirect to={`/login`} />;
};
