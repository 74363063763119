import React from "react";
// import { useRouteMatch } from "react-router-dom";

// import { OrganisationsIndex } from "../organisations/organisations.index";
// import { UserProfile } from "../user-profile/user-profile";
// import { OrganisationCreate } from "../organisations/organisation.create";
// import { OrganisationEdit } from "../organisations/organisation.edit";
type Props = {};

export const SettingsIndex: React.FC<Props> = (props) => {
    // const { path } = useRouteMatch();

    return (
        <>
            <h1>Einstellungen</h1>
            {/* <Switch>
                <Route path={`${path}/organisations`}>
                    <Switch>
                        <Route path={`${path}/organisations/create`}>
                            <OrganisationCreate />
                        </Route>
                        <Route path={`${path}/organisations/:id`}>
                            <OrganisationEdit />
                        </Route>
                        <Route path={`${path}`}>
                            <OrganisationsIndex />
                        </Route>
                    </Switch>
                </Route>
                <Route path={`${path}/profile`}>
                    <UserProfile />
                </Route>
            </Switch>
            <Link to="/settings/organisations">Stammdaten pflegen</Link> */}
        </>
    );
};
