export enum ErrorActions {
    SET_ERROR = "SET_ERROR",
}

export type ErrorState = { status: string; message: string } | null;

export const initialErrorState = null;

export type ErrorActionTypes = { type: ErrorActions.SET_ERROR; payload: ErrorState };

export const errorReducer = (state: ErrorState, action: ErrorActionTypes): ErrorState => {
    return state;
};
