import React, { MouseEvent, useEffect, useState } from "react";
import { Formik, Field, ErrorMessage, FieldInputProps, FormikProps } from "formik";
import { UploadArea } from "../../components/file-upload/upload-area";
import { Link } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "./styles.css";
import { organisationValidationSchema } from "./organisation.validation";
import { Organisation } from "../../entities/organisation.entity";
import { Image } from "../../entities/image.entity";
import { OrganisationDto } from "../../entities/organisation.dto";
import { User } from "../../entities/user.entity";
import { Authorized } from "../../access-control/authorized";

type Props = {
    id?: string;
    data: OrganisationDto;
    validationSchema: typeof organisationValidationSchema;
    save?: (data: OrganisationDto) => void;
    update?: (data: OrganisationDto, id: Organisation["_id"]) => void;
    remove?: (e: MouseEvent<HTMLButtonElement>) => void;
    handleSubmit: (values: OrganisationDto) => void;
    returnTo?: string | null;
    user?: User;
};

type FieldProps = { field: FieldInputProps<string>; form: FormikProps<OrganisationDto> };

export const OrganisationForm: React.FC<Props> = ({
    id,
    data,
    validationSchema,
    remove,
    handleSubmit,
    returnTo,
    ...props
}): JSX.Element => {
    const [initialValues, setInitialValues] = useState(data);

    useEffect(() => {
        if (!id) {
            delete initialValues.image;
        }
        setInitialValues(data);
    }, [data]);

    function setImage(image: Image[]) {
        setInitialValues((state) => ({ ...state, image: image[0] || null }));
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize={true}
        >
            {({ handleSubmit, isSubmitting }) => {
                return (
                    <form className="p-fluid" onSubmit={handleSubmit}>
                        {id && typeof initialValues.image !== "string" && (
                            <div className="p-field">
                                <UploadArea
                                    id={id}
                                    images={initialValues.image}
                                    setImages={setImage}
                                    disabled={!id}
                                    chooseLabel={initialValues.image ? `Bild ersetzen` : `Bild auswählen`}
                                />
                            </div>
                        )}
                        <div className="p-field">
                            <Field type="text" name="name">
                                {({ field, form }: FieldProps) => {
                                    return (
                                        <>
                                            <label htmlFor={field.name}>Firmenname *</label>
                                            <InputText
                                                type="text"
                                                {...field}
                                                id={`${field.name}`}
                                                placeholder="Firmenname (Pflichtfeld)"
                                            />
                                            <ErrorMessage name={field.name}>
                                                {(msg) => <small className="form__error-message">{msg}</small>}
                                            </ErrorMessage>
                                        </>
                                    );
                                }}
                            </Field>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-6">
                                <Field type="text" name="address.street">
                                    {({ field, form }: FieldProps) => {
                                        return (
                                            <>
                                                <label htmlFor={field.name}>Straße und Hausnr. *</label>
                                                <InputText
                                                    type="text"
                                                    {...field}
                                                    id={`${field.name}`}
                                                    placeholder="Straße und Hausnr. (Pflichtfeld)"
                                                />
                                                <ErrorMessage name={field.name}>
                                                    {(msg) => <small className="form__error-message">{msg}</small>}
                                                </ErrorMessage>
                                            </>
                                        );
                                    }}
                                </Field>
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <Field type="text" name="address.zip">
                                    {({ field, form }: FieldProps) => {
                                        return (
                                            <>
                                                <label htmlFor={field.name}>PLZ *</label>
                                                <InputText
                                                    type="text"
                                                    {...field}
                                                    id={`${field.name}`}
                                                    placeholder="PLZ (Pflichtfeld)"
                                                />
                                                <ErrorMessage name={field.name}>
                                                    {(msg) => <small className="form__error-message">{msg}</small>}
                                                </ErrorMessage>
                                            </>
                                        );
                                    }}
                                </Field>
                            </div>
                        </div>
                        <div className="p-field">
                            <Field type="text" name="address.city">
                                {({ field, form }: FieldProps) => {
                                    return (
                                        <>
                                            <label htmlFor={`${field.name}`}>Ort *</label>
                                            <InputText
                                                type="text"
                                                {...field}
                                                id={`${field.name}`}
                                                placeholder="Ort (Pflichtfeld)"
                                            />
                                            <ErrorMessage name={field.name}>
                                                {(msg) => <small className="form__error-message">{msg}</small>}
                                            </ErrorMessage>
                                        </>
                                    );
                                }}
                            </Field>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-6">
                                <Field type="text" name="email">
                                    {({ field, form }: FieldProps) => {
                                        return (
                                            <div className="form-field">
                                                <label htmlFor={`${field.name}`}>Kontakt-E-Mail-Adresse *</label>
                                                <ErrorMessage name={field.name}>
                                                    {(msg) => <div className="form__error-message">{msg}</div>}
                                                </ErrorMessage>
                                                <InputText
                                                    type="text"
                                                    required
                                                    {...field}
                                                    id={`${field.name}`}
                                                    placeholder="E-Mail (Pflichtfeld)"
                                                />
                                            </div>
                                        );
                                    }}
                                </Field>
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <Field type="text" name="phone">
                                    {({ field, form }: FieldProps) => {
                                        return (
                                            <div className="form-field">
                                                <label htmlFor={`${field.name}`}>Telefon *</label>
                                                <ErrorMessage name={field.name}>
                                                    {(msg) => <div className="form__error-message">{msg}</div>}
                                                </ErrorMessage>
                                                <InputText
                                                    type="text"
                                                    {...field}
                                                    id={`${field.name}`}
                                                    placeholder="Telefon (Pflichtfeld)"
                                                />
                                            </div>
                                        );
                                    }}
                                </Field>
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-6">
                                <Field type="text" name="tradeRegister">
                                    {({ field, form }: FieldProps) => {
                                        return (
                                            <div className="form-field">
                                                <label htmlFor={`${field.name}`}>Handelsregister-Nr.</label>
                                                <ErrorMessage name={field.name}>
                                                    {(msg) => <div className="form__error-message">{msg}</div>}
                                                </ErrorMessage>
                                                <InputText
                                                    type="text"
                                                    {...field}
                                                    id={`${field.name}`}
                                                    placeholder="Handelsregister-Nr."
                                                />
                                            </div>
                                        );
                                    }}
                                </Field>
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <Field type="text" name="vatNo">
                                    {({ field, form }: FieldProps) => {
                                        return (
                                            <div className="form-field">
                                                <label htmlFor={`${field.name}`}>USt-Ident-Nr.</label>
                                                <ErrorMessage name={field.name}>
                                                    {(msg) => <div className="form__error-message">{msg}</div>}
                                                </ErrorMessage>
                                                <InputText
                                                    type="text"
                                                    {...field}
                                                    id={`${field.name}`}
                                                    placeholder="USt-Ident-Nr."
                                                />
                                            </div>
                                        );
                                    }}
                                </Field>
                            </div>
                        </div>
                        <div className="p-field p-d-flex p-jc-end">* Pflichtfeld</div>
                        <div className="p-d-flex actions">
                            <Link
                                to={returnTo ? returnTo : "/settings/organisations"}
                                className="p-button p-button-outlined p-button-secondary p-text-center"
                            >
                                Abbrechen
                            </Link>

                            <Button type="submit" disabled={isSubmitting} label="Speichern" />
                        </div>
                        <div className="d-fluid p-mt-6">
                            {id && (
                                <Authorized entityId={id}>
                                    <Button
                                        className="p-mt-6 p-button-danger p-button-outlined"
                                        type="button"
                                        disabled={isSubmitting}
                                        label="Löschen"
                                        onClick={remove}
                                    />
                                </Authorized>
                            )}
                        </div>
                    </form>
                );
            }}
        </Formik>
    );
};
