import { OrganisationDto } from "../../entities/organisation.dto";

export let organisationBlueprint: OrganisationDto = {
    name: "",
    image: "",
    address: {
        street: "",
        zip: "",
        city: "",
    },
    email: "",
    phone: "",
    tradeRegister: "",
    vatNo: "",
};
