import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";

type Props = {
    organisation: string | undefined;
    store: boolean;
};

export const Onboarding: React.FC<Props> = ({ organisation, store }): JSX.Element => {
    return (
        <div className="p-d-flex p-flex-column p-ai-center p-jc-center">
            <section className="p-text-left">
                <h1>Willkommen bei tagesessen.com!</h1>
                <p>Wir freuen uns, dass Sie bei uns sind. So geht es jetzt weiter:</p>
                <ol className="onboarding-steps">
                    <li className={organisation ? "inactive" : "active"}>
                        <p>
                            Tragen Sie bitte zunächst Ihre <strong>Inhaberdaten</strong> ein:
                        </p>
                        {!organisation ? (
                            <Link
                                className="p-button p-button-outlined p-button-primary p-text-center"
                                to={`/settings/organisations/create`}
                            >
                                Inhaberdaten anlegen
                            </Link>
                        ) : (
                            <span className="p-button p-button-outlined p-button-secondary p-text-center button-disabled">
                                Inhaberdaten anlegen <i className="pi pi-check p-ml-2" />
                            </span>
                        )}
                    </li>
                    <li className={store || !organisation ? "inactive" : "active"}>
                        <p>
                            Bitte legen Sie im zweiten Schritt Ihre <strong>Filiale(n)</strong> an
                        </p>
                        {organisation && !store ? (
                            <Link
                                to={`/settings/organisations/${organisation}/stores/create`}
                                className="p-button p-button-outlined p-button-primary p-text-center"
                            >
                                Erstes Restaurant / Filiale anlegen
                            </Link>
                        ) : (
                            <span className="p-button p-button-outlined p-button-secondary p-text-center button-disabled">
                                Erstes Restaurant / Filiale anlegen <i className="pi pi-check p-ml-2" />
                            </span>
                        )}
                    </li>
                    <li className={store ? "active" : "inactive"}>
                        <p>
                            Pflegen Sie Ihre <strong>Angebote</strong> ein!
                        </p>
                    </li>
                </ol>
            </section>
        </div>
    );
};
