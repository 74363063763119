import { Dispatch } from "react";
import { Actions } from "../../state/root.reducer";
import axios from "axios";
import { StoreDto } from "../../entities/store.dto";
import { UiActions } from "../../state/ui.reducer";
import { makeToast } from "../../utils/make-toast";

async function getGeoCode(street: string, zip: string, city: string, dispatch: Dispatch<Actions>) {
    const url = `https://nominatim.openstreetmap.org/search?street=${street}&postalcode=${zip}&city=${city}&format=jsonv2&addressdetails=1`;
    try {
        const response = await axios({ url });
        const geocodes = await response.data;

        if (geocodes.length > 0) {
            const { lat, lon } = geocodes[0];
            return [parseFloat(lat), parseFloat(lon)];
        }
    } catch (e) {
        dispatch({
            type: UiActions.ADD_TOAST_MESSAGE,
            payload: makeToast({
                severity: "error",
                summary: "Fehlerhafte Adresse",
                detail: "Die eingegebene Adresse konnte nicht lokalisiert werden",
            }),
        });
    }
}

export async function calculateGeocode(
    values: StoreDto,
    setInitialFormValues: Function,
    dispatch: Dispatch<Actions>
): Promise<void> {
    const {
        address: { street = "", zip = "", city = "" },
    } = values;
    if (street && zip && city) {
        const geocode = await getGeoCode(street, zip, city, dispatch);
        const [lat = null, lon = null] = geocode || [];
        setInitialFormValues({
            ...values,
            location: {
                coordinates: [lon, lat],
            },
        });
    }
}
