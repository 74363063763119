import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Menu } from "primereact/menu";

import { useUser } from "../../hooks/useUser.repository";
import { AuthContext, DataContext } from "../../context";
import "./styles.css";
import { getStoresFromState } from "../../features/stores/get-stores-from-state";
import { Store } from "../../entities/store.entity";
import { MenuItem } from "primereact/components/menuitem/MenuItem";

type Props = {
    removeLocalAuth?: () => void;
    removeLocalUser?: () => void;
    removeLocalData?: () => void;
};

export const SidebarNavMenu: React.FC<Props> = ({ removeLocalAuth, removeLocalUser, removeLocalData }) => {
    const history = useHistory();
    const { activeOrganisation, stores, organisations } = useContext(DataContext);
    const { user } = useContext(AuthContext);
    const { logout } = useUser();

    const organisationStores = getStoresFromState(activeOrganisation?._id, stores).map((store: Store) => ({
        label: `${store.name} - Angebote`,
        icon: "pi pi-chevron-circle-right",
        command: () => {
            history.push(`/store/${store._id}/offers`);
        },
    }));

    const home = [
        {
            label: "Übersicht",
            icon: "pi pi-home",
            command: () => {
                history.push("/");
            },
        },
        {
            separator: true,
        },
    ];

    const legalItems = [
        {
            label: "Impressum",
            icon: "pi pi-info",
            command: () => {
                history.push("/impressum");
            },
        },
        {
            label: "Datenschutz",
            icon: "pi pi-lock",
            command: () => {
                history.push("/datenschutz");
            },
        },
        {
            label: "AGB",
            icon: "pi pi-briefcase",
            command: () => {
                history.push("/agb");
            },
        },
        {
            label: "Feedback geben",
            icon: "pi pi-envelope",
            command: () => {
                history.push("/feedback");
            },
        },
        {
            separator: true,
        },
        {
            label: "Logout",
            icon: "pi pi-power-off",
            command: async () => {
                await logout();
                removeLocalAuth && removeLocalAuth();
                removeLocalUser && removeLocalUser();
                removeLocalData && removeLocalData();

                // Junge Junge Junge...
                window.location.href = "/login";
                window.location.reload();
            },
        },
    ];

    const confirmedItems = [
        ...organisationStores,
        {
            label: "Inhaberdaten pflegen",
            icon: "pi pi-sliders-v",
            command: () => {
                organisations && organisations.length > 0
                    ? history.push("/settings/organisations")
                    : history.push("/settings/organisations/create");
            },
        },
        {
            separator: true,
        },
    ];

    const adminItems = [
        {
            label: "Administration",
            template: <h3>Administration</h3>,
            className: "menu-section__headline",
        },
        {
            label: "Alle Firmen",
            icon: "pi pi-list",
            command: () => {
                history.push("/admin/organisations");
            },
        },
        {
            separator: true,
        },
    ];

    let items: MenuItem[] = home;

    if (user?.isConfirmed) {
        items = [...home, ...confirmedItems];
    }

    if (user?.isAdmin) {
        items = [...items, ...adminItems];
    }

    items = [...items, ...legalItems];

    return <Menu className="sidebar__main-nav p-mt-2" model={items} />;
};
