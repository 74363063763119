import React, { MouseEvent, useState } from "react";
import { MapContainer, MapConsumer, Popup, Marker, TileLayer, ZoomControl } from "react-leaflet";
import "./styles.css";
import { LatLng } from "leaflet";

type Props = {
    center: [number, number];
    name: string;
    popupContent?: string;
    setFieldValue?: Function;
};

export const Map: React.FC<Props> = ({ center, name, popupContent, setFieldValue }): JSX.Element => {
    const [editable, setEditable] = useState<boolean>(false);

    function toggleEditMode(e: MouseEvent) {
        e.preventDefault();
        setEditable((editable) => !editable);
    }

    function setLatLng(latLng: LatLng) {
        const { lat, lng } = latLng;
        console.log(lat, lng);
        setFieldValue && setFieldValue(name, [lng, lat]);
    }

    return (
        <>
            <div className="p-fluid">
                <button onClick={toggleEditMode} className="plain-link-button" style={{ fontSize: "14px" }}>
                    {editable ? "Sie können die Markierung bearbeiten: " : "Markierung bearbeiten"}
                </button>
                {editable && (
                    <p style={{ fontSize: "14px", margin: 0 }}>
                        Halten und ziehen Sie die Markierung an die gewünschte Stelle
                    </p>
                )}
            </div>
            <div className="map-wrapper">
                <MapContainer
                    zoomControl={false}
                    scrollWheelZoom={false}
                    maxZoom={18}
                    zoom={16}
                    center={center}
                    style={{ height: "400px", width: "100%" }}
                >
                    <ZoomControl position="topleft" />
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors'
                        url={"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"}
                    />
                    <Marker
                        position={center}
                        draggable={editable}
                        eventHandlers={{ dragend: (e) => setLatLng(e.target.getLatLng()) }}
                    >
                        {/* needed for re-rendering the map on geocode change */}
                        <MapConsumer>
                            {(map) => {
                                map.setView(center, 16);
                                return null;
                            }}
                        </MapConsumer>
                        <Popup>{popupContent}</Popup>
                    </Marker>
                </MapContainer>
            </div>
        </>
    );
};

export default Map;
