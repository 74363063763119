import React, { useEffect, useState } from "react";
import { InputSwitch } from "primereact/inputswitch";

type Props = {
    show?: boolean;
    fallback?: string | JSX.Element;
    onDeactivate?: () => void;
    disabled?: boolean;
};

export const ConditionalSwitch: React.FC<Props> = ({
    show = false,
    fallback = null,
    onDeactivate = null,
    disabled = false,
    children,
}): JSX.Element => {
    const [showChildren, setShowChildren] = useState(show);

    useEffect(() => {
        !showChildren && onDeactivate && onDeactivate();
    }, [showChildren]);

    return (
        <div className={`conditional-switch__container${showChildren ? " conditional-switch__container--show" : ""}`}>
            <InputSwitch
                className="conditional-switch"
                checked={showChildren}
                onChange={(e) => setShowChildren(e.value)}
                style={{ margin: "0 10px" }}
                disabled={disabled}
            />
            {showChildren ? (
                <div className="conditional-wrapped-content">{children}</div>
            ) : (
                <span className="conditional-switch__fallback">{fallback}</span>
            )}
        </div>
    );
};
