import { Dispatch, useEffect } from "react";
import { State, Actions } from "../state/root.reducer";

import { useStore } from "./useStore.repository";

export function useHydrateState(state: State, dispatch: Dispatch<Actions>): void {
    const { getAll } = useStore(state.activeOrganisation?._id);

    useEffect(() => {
        (async () => {
            if (state.activeOrganisation?._id) {
                await getAll();
            }
        })();
    }, [state.activeOrganisation?._id]);

    // const mounted = useRef<boolean>();
}
