import React, { useContext, createRef, useState } from "react";
import axios from "axios";
import { FileUpload as PrimeReactFileUpload } from "primereact/fileupload";
import { ProgressBar } from "primereact/progressbar";
import { AuthContext } from "../../context/index";
import { Image } from "../../entities/image.entity";
import "./styles.css";

const { REACT_APP_API_URL: API_URL, REACT_APP_API_VERSION: API_VERSION } = process.env;

type Props = {
    id: string;
    disabled: boolean;
    chooseLabel?: string;
    multiple?: boolean;
    fieldName?: string;
    setImages: (imgs: Image[]) => void;
};

export const FileUpload: React.FC<Props> = ({ id, disabled, chooseLabel, fieldName, multiple = false, setImages }) => {
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const { auth } = useContext(AuthContext);
    const uploadRef = createRef<PrimeReactFileUpload>();

    async function upload(event: { files: any }) {
        const formData = new FormData();

        // const filesToUpload = event.files.filter((file: File) => !filesRef.current.includes(file.name));

        if (multiple) {
            event.files.forEach((file: File) => {
                formData.append(fieldName || "files", file);
            });
        } else {
            formData.append(fieldName || "files", event.files[0]);
        }

        const response = await axios({
            url: `${API_URL}/${API_VERSION}/upload`,
            method: "POST",
            onUploadProgress: (event: ProgressEvent) => {
                setUploadProgress(Math.floor((event.loaded / event.total) * 100));
            },
            headers: {
                // "Content-Type": "multipart/form-data",
                "x-tagesessen-organisation-id": id,
                "Authorization": `Bearer ${auth?.token}`,
            },
            data: formData,
        });

        const uploadedImages: Image[] = response.data;

        uploadRef.current?.clear();

        setImages(uploadedImages);
    }

    return (
        <>
            <PrimeReactFileUpload
                ref={uploadRef}
                disabled={disabled}
                customUpload
                auto
                multiple={multiple}
                name={fieldName || "files"}
                accept="image/*"
                uploadHandler={(event) => upload(event)}
                chooseLabel={chooseLabel}
            />
            {uploadProgress > 0 && <ProgressBar value={uploadProgress} color="#01a29b"></ProgressBar>}
        </>
    );
};
