import React, { useEffect } from "react";
import { useUser } from "../../hooks/useUser.repository";

type Props = {
    userId: string | undefined;
    token: string | undefined;
};

export const WithUser: React.FC<Props> = ({ userId, token, children }): JSX.Element => {
    const { getUser } = useUser();

    useEffect(() => {
        (async () => {
            if (userId) {
                await getUser(userId);
            }
        })();
    }, [userId]);

    return <>{children}</>;
};
