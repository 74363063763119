import React from "react";

type Props = {
    title?: string;
    subtitle?: string;
};

export const Headline: React.FC<Props> = ({ title, subtitle }): JSX.Element => {
    return (
        <header className="headline">
            {title && <h1 className="headline__title">{title}</h1>}
            {subtitle && <h2 className="headline__subtitle">{subtitle}</h2>}
        </header>
    );
};
