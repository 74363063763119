import React, { useContext } from "react";
import { Sidebar as PrimeReactSidebar } from "primereact/sidebar";
import { SidebarNavMenu } from "./sidebar-nav-menu";
import { DispatchContext } from "../../context";
import { UiActions } from "../../state/ui.reducer";
import { OrganisationSwitch } from "../../components/organisation-switch";
import { useLocalStorage } from "../../hooks/use-localstorage";
import { initialAuthState } from "../../state/auth.reducer";
import { initialUserState } from "../../state/user.reducer";
import { combinedInitialDataState } from "../../state/root.reducer";

type Props = {
    sidebarVisible: boolean | undefined;
};

export const Sidebar: React.FC<Props> = ({ sidebarVisible }): JSX.Element => {
    const { dispatch } = useContext(DispatchContext);
    const [, , removeLocalAuth] = useLocalStorage("auth", initialAuthState);
    const [, , removeLocalUser] = useLocalStorage("user", initialUserState);
    const [, , removeLocalData] = useLocalStorage("appData", combinedInitialDataState);

    return (
        <PrimeReactSidebar
            visible={sidebarVisible}
            onHide={() => dispatch({ type: UiActions.SET_SIDEBAR_VISIBLE, payload: false })}
        >
            <OrganisationSwitch />
            <SidebarNavMenu
                removeLocalAuth={removeLocalAuth}
                removeLocalUser={removeLocalUser}
                removeLocalData={removeLocalData}
            />
        </PrimeReactSidebar>
    );
};
