import React, { useContext, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { Store } from "../../entities/store.entity";
import { useStore } from "../../hooks/useStore.repository";
import { Link } from "react-router-dom";
import { getOffersFromState } from "../offers/get-offers-from-state";
import { sortOffersByDate } from "../../utils/sort-offers-by-date";
import { Offer } from "../../entities/offer.entity";
import { Image } from "../../entities/image.entity";
import { PaginatedResult } from "../../entities/paginated-result";

type Props = {
    name: string;
    id: Store["_id"];
    max?: number;
};

const { REACT_APP_API_URL: API_URL } = process.env;

export const StoreOffersPreview: React.FC<Props> = ({ name, id, max }): JSX.Element => {
    const [offers, setOffers] = useState<PaginatedResult<Offer> | undefined>();
    const { getOffers } = useStore(undefined, id);

    useEffect(() => {
        (async () => {
            const offers = await getOffers({
                startDate: new Date(),
            });
            if (offers) {
                setOffers(offers);
            }
        })();
    }, [id, max]);

    // const storeOffers = offers.sort(sortOffersByDate).slice(0, 4);

    const comingFiveOffers = offers?.docs
        .filter((offer: Offer) => {
            const offerDate = new Date(offer.date).setHours(0, 0, 0, 0);
            const today = new Date().setHours(0, 0, 0, 0);
            return offerDate >= today;
        })
        .slice(0, 4);

    return (
        <div className="datatable-responsive">
            <div className="card">
                <DataTable
                    value={comingFiveOffers}
                    className="p-datatable-responsive"
                    rows={10}
                    header={
                        <div className="p-d-flex p-jc-between p-ai-center">
                            <div className="table-heading">
                                <span>{name} / Kommende Angebote</span>
                            </div>
                            <Link to={`/store/${id}/offers`}>
                                Alle Angebote <i className="pi pi-arrow-right" style={{ fontSize: ".8em" }}></i>
                            </Link>
                        </div>
                    }
                >
                    <Column field="name" header="Titel"></Column>
                    <Column
                        field="image"
                        header="Bild"
                        body={(offer: Offer): JSX.Element | null => {
                            const image = offer.image as Image;
                            return image ? (
                                <img
                                    alt={image.caption}
                                    className="offer-item__image"
                                    src={`${API_URL}/${image?.renditions.square.path}`}
                                />
                            ) : null;
                        }}
                    ></Column>
                    <Column
                        field="price"
                        header="Preis"
                        body={(offer: Offer): string =>
                            offer.price
                                ? offer.price.toLocaleString("de-DE", { style: "currency", currency: "EUR" })
                                : "--"
                        }
                    ></Column>
                    <Column
                        field="date"
                        header="Angebot am"
                        body={(offer: Offer): string =>
                            offer.date ? new Date(offer.date).toLocaleDateString() : "Speisekarte"
                        }
                    ></Column>
                </DataTable>
            </div>
        </div>
    );
};
