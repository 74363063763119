import axios from "axios";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Link, useRouteMatch } from "react-router-dom";
import { Organisation } from "../../../entities/organisation.entity";
import { Store } from "../../../entities/store.entity";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useQuery } from "../../../hooks/use-query";
import { useOrganisation } from "../../../hooks/useOrganisation.repository";
import "./styles.css";

type Props = {};

type PagingInfo = {
    hasNextPage: boolean;
    hasPrevPage: boolean;
    limit: number;
    nextPage: number | null;
    page: number;
    pagingCounter: number;
    prevPage: number | null;
    totalDocs: number;
    totalPages: number;
};

export const AdminOrganisationsIndex: React.FC<Props> = (props): JSX.Element => {
    const { url } = useRouteMatch();
    const query = useQuery();
    const [organisations, setOrganisations] = useState<Organisation[]>([]);
    const [pagingInfo, setPagingInfo] = useState<PagingInfo>();
    const [activeIndex, setActiveIndex] = useState<number>();
    const [loading, setLoading] = useState<boolean>(false);
    const [searchPhrase, setSearchPhrase] = useState<string>(query.get("searchTerm") || "");
    const { download } = useOrganisation();

    const returnTo = encodeURIComponent(`${url}?searchTerm=${searchPhrase}`);

    useEffect(() => {
        (async () => {
            await loadMore();
        })();
    }, []);

    function renderStores(stores: Store[]) {
        return (
            <>
                <h4 className="p-mb-0">Filialen</h4>
                <ul className="p-mx-0 p-mt-2 p-px-0">
                    {stores.map((store: Store) => {
                        return (
                            <li key={store._id} className="admin__store-list-item p-d-flex p-jc-between p-py-2">
                                <Link
                                    to={`/settings/organisations/${store.organisation}/store/${store._id}?returnTo=${returnTo}`}
                                >
                                    {store.name}
                                </Link>{" "}
                                <Link to={`/store/${store._id}/offers`} className="p-text-right">
                                    Angebote bearbeiten
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </>
        );
    }

    async function fetchOrganisations(e: FormEvent) {
        e.preventDefault();
        await loadMore(1);
    }

    async function loadMore(page?: number) {
        setLoading(true);
        try {
            const getPage = !page && pagingInfo?.nextPage ? `&page=${pagingInfo.nextPage}` : "";
            const response = await axios({
                url: `/organisations/all?search=${searchPhrase}&populate=stores${getPage}`,
            });
            const { docs, ...paging } = response.data;
            if (paging.hasPrevPage) {
                setOrganisations([...organisations, ...docs]);
            } else {
                setOrganisations(docs);
            }
            setPagingInfo(paging);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    function clearSearchPhrase() {
        setSearchPhrase("");
    }

    async function downloadData() {
        await download(searchPhrase, "firmen.csv");
    }

    useEffect(() => {
        (async () => {
            if (searchPhrase.length === 0) {
                await loadMore();
            }
        })();
    }, [searchPhrase]);

    return (
        <>
            <h1>Firmenübersicht</h1>
            <form onSubmit={fetchOrganisations} className="p-fluid p-my-4 p-d-flex">
                <div className="p-col-12" style={{ flexShrink: 1 }}>
                    <span className="p-input-icon-right">
                        {searchPhrase.length > 0 && (
                            <i className="pi pi-times" style={{ cursor: "pointer" }} onClick={clearSearchPhrase} />
                        )}
                        <InputText
                            value={searchPhrase}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setSearchPhrase(e.target.value);
                            }}
                        />
                    </span>
                </div>
                <div className="p-col-12" style={{ width: "100px" }}>
                    <Button label={"Suchen"} type="submit" />
                </div>
            </form>
            <>
                {organisations.length > 0 && (
                    <>
                        <p className="p-d-flex p-jc-between">
                            <span>
                                Insgesamt <strong>{pagingInfo?.totalDocs}</strong> Ergebnis
                                {pagingInfo && pagingInfo?.totalDocs > 1 && "se"}
                            </span>
                            <button
                                onClick={downloadData}
                                className="plain-link-button p-text-right"
                                style={{ fontSize: "16px" }}
                            >
                                <i className="pi pi-download"></i> {pagingInfo?.totalDocs} Ergebnis
                                {pagingInfo && pagingInfo?.totalDocs > 1 && "se"} herunterladen
                            </button>
                        </p>
                        <Accordion activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                            {organisations.map((organisation: Organisation) => {
                                return (
                                    <AccordionTab
                                        key={organisation._id}
                                        header={`${organisation.name}, ${organisation.address.city}`}
                                    >
                                        <Link to={`/settings/organisations/${organisation._id}?returnTo=${returnTo}`}>
                                            Inhaberdaten von {organisation.name} bearbeiten
                                        </Link>
                                        {organisation.stores.length > 0 && renderStores(organisation.stores as Store[])}
                                    </AccordionTab>
                                );
                            })}
                        </Accordion>
                    </>
                )}
                {pagingInfo?.hasNextPage && (
                    <div className="p-d-flex p-jc-center p-my-4">
                        <Button label={loading ? "Lädt..." : "Mehr laden"} onClick={() => loadMore()} />
                    </div>
                )}
            </>
        </>
    );
};
