import { createContext } from "react";
import { AuthState } from "../state/auth.reducer";
import { UserState } from "../state/user.reducer";

export interface AuthAndUserState {
    auth: AuthState;
    user: UserState;
}

export const AuthContext = createContext<Partial<AuthAndUserState>>({});
