import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { OrganisationChoice } from "../organisations/organisation-choice-modal";
import { DataContext } from "../../context";
import { Store } from "../../entities/store.entity";
import { useOrganisation } from "../../hooks/useOrganisation.repository";
import { Modal } from "../../layout";
import { getStoresFromState } from "./get-stores-from-state";
import { StoreOffersPreview } from "../dashboard/store-offers-preview";

export const StoreIndex: React.FC = (): JSX.Element => {
    const { activeOrganisation, organisations, stores } = useContext(DataContext);
    const { getStores } = useOrganisation(activeOrganisation?._id);

    useEffect(() => {
        (async () => {
            // if (activeOrganisation?._id) {
            await getStores();
            // }
        })();
    }, [activeOrganisation?._id]);

    const activeStores = getStoresFromState(activeOrganisation?._id, stores);

    return (
        <>
            {!activeOrganisation && organisations && organisations.length > 1 ? (
                <Modal>
                    <>
                        <h3>Bitte wählen Sie die Firma aus, für die Sie arbeiten wollen</h3>
                        <OrganisationChoice />
                    </>
                </Modal>
            ) : (
                <h1>{activeOrganisation?.name}</h1>
            )}
            {!activeStores.length && activeOrganisation ? (
                <Link to={`/settings/organisations/${activeOrganisation?._id}/stores/create`}>
                    Erste Filiale für {activeOrganisation.name} anlegen
                </Link>
            ) : (
                activeStores.map((store: Store) => (
                    <section key={store._id} className="store-offers-preview">
                        <StoreOffersPreview name={store.name} id={store._id} max={5} />
                    </section>
                ))
            )}
        </>
    );
};
