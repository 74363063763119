import React from "react";
import { Link } from "react-router-dom";
import { Layout } from "../../layout";
type Props = {};

export const ActivationFailed: React.FC<Props> = (props): JSX.Element => {
    return (
        <Layout>
            <h1>Aktivierung fehlgeschlagen</h1>
            <p>Der Account existiert nicht oder ist bereits aktiviert worden.</p>
            <p>
                <Link to="/login">Zum Login</Link>
            </p>
        </Layout>
    );
};
