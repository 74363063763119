import React, { useContext } from "react";
import { Redirect, useRouteMatch } from "react-router-dom";
// import { Authorized } from "../../access-control/authorized";
import { AuthContext, DataContext } from "../../context";
import { Organisation } from "../../entities/organisation.entity";
import { OrganisationDetail } from "./organisation.detail";
import "./styles.css";

export const OrganisationsIndex: React.FC = (): JSX.Element => {
    const { path } = useRouteMatch();
    const { user } = useContext(AuthContext);
    const { organisations, stores } = useContext(DataContext);

    return (
        <>
            <h1>{user && user.roles.length > 1 ? "Meine Firmen" : "Stammdaten"}</h1>
            {organisations && organisations.length > 0 ? (
                <div className="p-flex">
                    {organisations.map((organisation: Organisation) => {
                        const { _id } = organisation;
                        const organisationStores = stores && stores[_id] ? stores[_id] : [];

                        return (
                            <OrganisationDetail
                                key={organisation._id}
                                organisation={organisation}
                                stores={organisationStores}
                                path={path}
                            />
                        );
                    })}
                </div>
            ) : (
                <Redirect to="/" />
            )}
            {/* {organisations && organisations.length > 0 && user && user.roles.length > 0 && (
                <Authorized requireAdmin>
                    <Link className="p-button p-component p-mt-4" to="/settings/organisations/create">
                        Weitere Firma anlegen
                    </Link>
                </Authorized>
            )} */}
        </>
    );
};
