import React from "react";
import { useHistory } from "react-router-dom";

import { OrganisationForm } from "./organisation.form";
import { organisationValidationSchema } from "./organisation.validation";
import { useOrganisation } from "../../hooks/useOrganisation.repository";

import { organisationBlueprint } from "./organisation.blueprint";
import { OrganisationDto } from "../../entities/organisation.dto";

// import { WithUserAndOrga } from "../../components/hoc/with-user-and-orga";

// const OrganisationFormWithUserAndOrga = WithUserAndOrga(OrganisationForm);

export const OrganisationCreate: React.FC = (): JSX.Element => {
    const { create } = useOrganisation();
    const history = useHistory();

    async function handleSubmit(values: OrganisationDto) {
        // const valuesWithImagesTransformedToIds =
        //     values.image && isType<Image>(values.image, "image")
        //         ? {
        //               ...values,
        //               image: values.image?._id || null,
        //           }
        //         : values;

        await create(values);

        history.push("/settings/organisations");
    }

    return (
        <>
            <h1>Firma anlegen</h1>
            <OrganisationForm
                data={organisationBlueprint}
                validationSchema={organisationValidationSchema}
                handleSubmit={handleSubmit}
            />
        </>
    );
};
