import { useContext } from "react";
import { useRequest } from "./use-request";

import { DispatchContext } from "../context/dispatch.context";
import { Organisation } from "../entities/organisation.entity";

import { OrganisationsActions } from "../state/organisation.reducer";
import { UiActions } from "../state/ui.reducer";
import { Store } from "../entities/store.entity";
import { Offer } from "../entities/offer.entity";
import { OfferDto } from "../entities/offer.dto";
import { OffersActions } from "../state/offers.reducer";
import { makeToast } from "../utils/make-toast";
import { PaginatedResult } from "../entities/paginated-result";

type ReturnType = {
    create: (data: OfferDto) => void;
    getAll: () => void;
    getOffers: (
        storeId: string,
        {
            startDate,
            endDate,
            includeNullDates,
            page,
        }: {
            startDate?: Date;
            endDate?: Date;
            includeNullDates?: boolean;
            page?: number;
        }
    ) => Promise<PaginatedResult<Offer> | void>;
    get: () => void;
    update: (data: OfferDto, id: Store["_id"]) => void;
    remove: (id: Offer["_id"]) => void;
};

export const useOffer = (storeId?: Store["_id"], id?: Offer["_id"]): ReturnType => {
    const { dispatch } = useContext(DispatchContext);

    const { request } = useRequest();

    const getAll = async (): Promise<void> => {
        const response = await request<Organisation[]>({
            url: `/stores/?populate=images`,
            withCredentials: false,
        });

        if (response.status >= 400) {
            dispatch({
                type: UiActions.ADD_TOAST_MESSAGE,
                payload: makeToast({
                    severity: "error",
                    summary: "Fehler",
                    detail: "Stammdaten konnten nicht geladen werden",
                }),
            });
        } else {
            dispatch({
                type: OrganisationsActions.ALL_ORGANISATIONS_RECEIVED,
                payload: response.data,
            });
        }
    };

    const getOffers = async (
        storeId: string,
        {
            startDate,
            endDate,
            includeNullDates,
            page,
        }: {
            startDate?: Date;
            endDate?: Date;
            includeNullDates?: boolean;
            page?: number;
        }
    ): Promise<PaginatedResult<Offer> | void> => {
        if (storeId) {
            let query = ``;
            query += startDate ? `&startDate=${startDate}` : "";
            query += endDate ? `&endDate=${endDate}` : "";
            query += includeNullDates === false ? `&includeNullDates=false` : "";
            query += page ? `&page=${page}` : "";

            const response = await request<PaginatedResult<Offer>>({
                url: `/stores/${storeId}/offers?populate=image${query}`,
                withCredentials: false,
            });

            if (response.status >= 400) {
                dispatch({
                    type: UiActions.ADD_TOAST_MESSAGE,
                    payload: makeToast({
                        severity: "error",
                        summary: "Fehler",
                        detail: "Filialen konnten nicht geladen werden",
                    }),
                });
            } else {
                return response.data;
            }
        }
    };

    const create = async (data: OfferDto): Promise<void> => {
        const response = await request<Offer>({
            url: `/offers`,
            method: "POST",
            data,
        });

        if (response.status >= 400) {
            dispatch({
                type: UiActions.ADD_TOAST_MESSAGE,
                payload: makeToast({
                    severity: "error",
                    summary: "Fehler",
                    detail: "Angebote konnten nicht gespeichert werden",
                }),
            });
        } else {
            dispatch({
                type: OffersActions.OFFER_CREATED,
                payload: {
                    id: storeId!,
                    offer: response.data,
                },
            });
        }
    };

    const get = async (): Promise<Offer | void> => {
        const response = await request<Offer>({
            url: `/stores/${id}?populate=images`,
            withCredentials: false,
        });

        if (response.status >= 400) {
            dispatch({
                type: UiActions.ADD_TOAST_MESSAGE,
                payload: makeToast({
                    severity: "error",
                    summary: "Fehler",
                    detail: "Angebote konnten nicht geladen werden",
                }),
            });
        } else {
            return response.data;
        }
    };

    const update = async (data: OfferDto, id: Offer["_id"]): Promise<Offer | void> => {
        const response = await request<Offer>({ url: `/offers/${id}`, method: "PATCH", data });

        if (response.status >= 400) {
            dispatch({
                type: UiActions.ADD_TOAST_MESSAGE,
                payload: makeToast({
                    severity: "error",
                    summary: "Fehler",
                    detail: "Das Angebot konnte nicht aktualisiert werden",
                }),
            });
        } else {
            return response.data;
        }
    };

    const remove = async (id: Offer["_id"]) => {
        const response = await request<undefined>({ url: `/offers/${id}`, method: "DELETE" });
        if (response.status >= 400) {
            dispatch({
                type: UiActions.ADD_TOAST_MESSAGE,
                payload: makeToast({
                    severity: "error",
                    summary: "Fehler",
                    detail: "Angebot konnte nicht gelöscht werden",
                }),
            });
        } else {
            return id;
            // dispatch({ type: OffersActions.OFFER_DELETED, payload: { id: storeId!, offerId: id! } });
        }
    };

    return { getAll, getOffers, create, get, update, remove };
};
