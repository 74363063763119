import { Organisation } from "./organisation.entity";

export enum Roles {
    admin = "admin",
    owner = "owner",
    editor = "editor",
}

export type Role = {
    role: Roles;
    organisation: Organisation["_id"];
};
