import React from "react";
import "./styles.css";

type Props = {};

export const DSE: React.FC<Props> = (props): JSX.Element => {
    return (
        <section className="legal">
            <h1>Datenschutzerklärung</h1>
            <h2>Einleitung</h2>
            <p>
                Diese Datenschutzrichtlinie hat die Aufgabe, Dich über die Verarbeitung von personenbezogenen Daten in
                unseren Unternehmen zu informieren. Wir kommen hiermit unseren Verpflichtungen aus dem Telemediengesetz
                (TMG) und der europäischen Datenschutz-Grundverordnung (EU-DSGVO, EU 2016/679) insbesondere Artikel 13
                und 14, sowie Artikel 26 Abs. 2 nach. Bitte lies diese Bestimmung sorgfältig durch. Solltest Du weitere
                Fragen haben, so kontaktiere uns bitte. Für Silverdust hat der Schutz Deiner Privatsphäre stets höchste
                Priorität. Silverdust ist Inhaber des Portals Tagesessen. Der Schutz Deiner personenbezogenen Daten ist
                uns sehr wichtig. Diese Bestimmung beschreibt unseren Umgang mit bestimmten Informationen über Dich,
                Deinen Computer oder Dein Mobilgerät („Gerät“). Diese Informationen enthalten möglicherweise
                personenbezogene Daten. Wir erklären in diesem Dokument auch, wie wir Cookies und Analysetools auf
                unserer gesamten Webseite und in unseren Produkten und Dienstleistungen verwenden. Bitte beachte, dass
                unsere Datenschutzrichtlinie je nach betroffenem Produkt oder Dienstleistung um zusätzliche Angaben
                ergänzt werden kann. Wir halten uns jederzeit an das geltende Datenschutzrecht und diese
                Datenschutzrichtlinie. Daten geben wir nur in den Fällen weiter, die in dieser Bestimmung aufgeführt
                werden.
            </p>
            <h2>Wie kannst Du uns kontaktieren?</h2>
            <p>
                Unseren Datenschutzbeauftragten erreichst Du unter:
                <br />
                Silverdust GmbH <br />
                Summer-Breeze-Weg 1<br />
                73453 Abtsgmünd
                <br />
                info@tagesessen.com
                <br />
            </p>
            <h2>Was verstehen wir unter bestimmten Begriffen?</h2>
            <h3>Anonymisierung</h3>
            <p>Durch Veränderung der Daten ist eine Zuordnung zu einer natürlichen Person nicht mehr möglich.</p>
            <h3>Aktivitätsdaten</h3>
            <p>Daten, welche über die Aktivitäten des Users gespeichert werden.</p>
            <h3>Analyse-Tools</h3>
            <p>Programme, die eine Auswertung des Nutzerverhaltens ermöglichen. </p>
            <h3>Cloud</h3>
            <p>
                Nutzung von IT-Infrastrukturen und -Dienstleistungen, die nicht vor Ort auf lokalen Rechnern
                vorgehalten, sondern als Dienst gemietet werden und auf die über ein Netzwerk (z. B. das Internet)
                zugegriffen wird
            </p>
            <h3>Cookies</h3>
            <p>Cookies sind kleine Textdateien, die auf Ihrem Rechner oder in Deinem Browser abgelegt werden.</p>
            <h3>Geräte</h3>
            <p>
                Ein (beweglicher) Gegenstand, wie z. B. Smartphones, Tablets, Notebook, PC, mit dem man Apps oder
                Programme und Informationsdienste nutzen kann.
            </p>
            <h3>Personenbezogene Daten</h3>
            <p>
                Dies sind Informationen, die sich auf eine bestimmte oder bestimmbare natürliche, lebende Person
                beziehen.
            </p>
            <h3>Pseudonymisierung</h3>
            <p>
                Veränderung der Daten in einer Art und Weise, dass sie ohne Hinzuziehung zusätzlicher Informationen
                nicht mehr einer spezifischen betroffenen Person zugeordnet werden können.
            </p>
            <h3>Schadsoftware</h3>
            <p>Programme, die dazu programmiert wurden, auf einem Gerät einen Schaden anzurichten.</p>
            <h2>Welche personenbezogenen Daten werden von uns verarbeitet?</h2>
            <p>
                Wir verarbeiten, wenn Du unsere Leistungen nutzt, unsere Webseiten besuchst, unterschiedliche Daten.
                Diese können ggf. direkt oder indirekt, das heißt unter Einwirkung anderer Datenquellen, personenbezogen
                sein. Viele dieser Daten erheben wir in pseudonymisierter oder anonymisierter Form. Dazu gehören u. a.
                folgende Informationen:
            </p>
            <h3>Informationen beim Besuch unserer Webseiten:</h3>
            <p>
                Wenn Du unsere Webseite besuchst, speichern wir unter Umständen Informationen über die Region aus der Du
                die Seite aufrufst, Informationen über Dein Gerät, Betriebssystem und Browser, über die Nutzung unserer
                Seite und ggf. ob Du uns schon besucht hast.
            </p>
            <h3>Informationen zur Registrierung:</h3>
            <p>
                Um einige unserer Produkte oder Dienstleistungen zu nutzen, ist ein Account erforderlich. Wir erheben
                während der Einrichtung Deines Kontos personenbezogene Daten, zum Beispiel Deinen Namen, E-Mail- und
                IP-Adresse, ggf. ergänzt um die Telefonnummer und Deine Adressdaten.
            </p>
            <p>
                Bei Mobil-Produkten kommen ggf. noch weitere Informationen, wie z. B. über das eingesetzte Gerät und das
                Betriebssystem, hinzu.
            </p>
            <h3>Support-Anfragen:</h3>
            <p>
                Wenn Du mit uns in Kontakt trittst, um z. B. eine Supportanfrage zu platzieren, speichern wir Daten zu
                dieser Anfrage, wie z. B. Kontaktdaten und Deinen Namen.
            </p>
            <h3>Informationen zum Standort:</h3>
            <p>Für gewisse Funktionen greifen wir auf die Standortdaten Deines Geräts zu. </p>
            <h2>Warum und auf welcher rechtlichen Grundlage speichern wir personenbezogene Daten?</h2>
            <h3>Verarbeitungszweck:</h3>
            <p>
                Wir verarbeiten Deine Daten unabhängig davon, ob sie einer Person zuzuordnen sind oder nicht, zu
                folgenden Zwecken:
            </p>
            <ul>
                <li>Um unseren vertraglichen Verpflichtungen Dir gegenüber nachzukommen.</li>
                <li>Eines reibungslosen Betriebs unserer Produkte und Dienstleistungen.</li>
                <li>Einer komfortablen und einfachen Nutzung unserer Produkte und Dienstleistungen.</li>
                <li>
                    Verbesserung und Optimierung der Funktionen, der Sicherheit und Stabilität unserer Produkte und
                    Dienstleistungen.
                </li>
                <li>Zu administrativen Zwecken.</li>
                <li>Um Dir für Dich optimierte Werbung und Produktinformationen anbieten zu können. </li>
                <li>Um rechtlichen / öffentlichen Auflagen nachzukommen.</li>
            </ul>
            <h3>Vertragsanbahnung und -erfüllung</h3>
            <p>
                Grundsätzlich speichern wir nur Daten, die wir zur Erfüllung unserer vertraglichen Verpflichtungen Dir
                gegenüber benötigen.
            </p>
            <h3>Rechtliche Verpflichtungen</h3>
            <p>
                Auf Grund von rechtlichen Vorgaben der Behörden sind wir dazu verpflichtet, Daten zu erheben, zu
                speichern und an Behörden bzw. deren Vertreter weiterzugeben. Dieses kann auch zur Erfüllung einer
                öffentlichen Aufgabe geschehen.
            </p>
            <h3>Einwilligung</h3>
            <p>
                An einigen Stellen ist es möglich, dass wir eine Datenverarbeitung auf Grundlage einer Einwilligung
                anbieten. In diesen Fällen weisen wir Dich gesondert auf diesen Sachverhalt hin und bieten Dir an, uns
                diese Verarbeitung zu erlauben. In diesen Fällen benennen wir Dir den Zweck der Datenverarbeitung und
                informieren Dich über Dein Widerrufsrecht.
            </p>
            <h3>Berechtigte Interesse</h3>
            <p>
                Es besteht auch die Möglichkeit, Daten auf Grundlage unseres berechtigten Interesses zu verarbeiten.
                Dabei sind wir verpflichtet, Dir unser Interesse offenzulegen und eine Abwägung zwischen Deinem und
                unserem Interesse vorzunehmen. In folgenden Prozessen ist das der Fall:
            </p>
            <h3>Speicherdauer und Löschfristen</h3>
            <p>
                Wir speichern die personenbezogenen Daten nur im zur Zweckerfüllung nötigen Umfang. Die Speicherdauer
                richtet sich nach den gesetzlichen Vorgaben und der Dauer der vertraglichen Beziehung. Sollten die Daten
                nicht mehr genutzt werden, werden sie im Rahmen der gesetzlichen Vorschriften anonymisiert und / oder
                gelöscht. Solltest Du die Löschung Deiner Daten veranlassen, so beachte bitte, dass wir Deine Daten zwar
                umgehend sperren, es auf Grund von technischen Einschränkungen aber bis zu 30 Tage dauern kann, bis wir
                die Daten endgültig gelöscht haben. Bitte beachte des Weiteren, dass nach der Bestätigung des
                Löschauftrages keine Möglichkeit mehr besteht, Deine Daten wiederherzustellen.
            </p>
            <h2>Wie verwenden wir Cookies, Analyse- und Tracking-Tools sowie Social-Media-Anmeldungen?</h2>
            <p>
                Wenn Du eine unserer Webseiten oder Dienstleistungen nutzt, werden Cookies in Deinen Browser geladen.
                Durch Cookies kann möglicherweise Dein Browser identifiziert werden, damit unsere Webseite korrekt
                angezeigt wird. Wir setzen außerdem auf unserer Webseite an verschiedenen Stellen Cookies ein, um die
                Nutzung unserer Webseite zu analysieren und diese dadurch zu optimieren, beispielsweise die
                Warenkorbfunktion bei Bestellungen in unseren Onlineshops.
            </p>
            <p>
                Neben eigenen Systemen setzen wir zu Marketingzwecken und um Deinen Besuch auf unserer Webseite oder die
                Verwendung unserer Produkte/Dienstleistungen benutzerfreundlicher zu gestalten, neben anderen auch die
                folgenden Tools anderer Anbieter ein.
            </p>
            <h3>Analyse-Tools</h3>
            <h4>Google Analytics</h4>
            <p>
                Wir verwenden Google Analytics, einen Webanalysedienst der Google Inc. („Google“). Google Analytics
                setzt Cookies ein, die es uns ermöglichen, Deine Nutzung unserer Webseite zu analysieren. Die durch
                Cookies generierten Daten über Deine Benutzung unserer Webseite werden in der Regel auf europäischen
                Servern nach den Richtlinien der DSGVO verarbeitet. Es besteht die Möglichkeit, dass Google die Daten an
                einen Server in den USA überträgt und dort speichert. Davor wird Deine IP-Adresse von Google jedoch,
                sofern sie aus einem Mitgliedstaat der Europäischen Union oder in anderen Vertragsstaaten des Abkommens
                über den Europäischen Wirtschaftsraum stammt, gekürzt und damit anonymisiert (anonymizeIP-Verfahren von
                Google). Nur in Ausnahmefällen wird die ungekürzte IP-Adresse an einen Server von Google in den USA
                übertragen und dort gespeichert. Durch die Anonymisierung wird sichergestellt, dass Deine IP-Adresse
                nicht zu Dir zurückverfolgt werden kann. Google verwendet diese Daten, um Deine Nutzung der Webseite
                auszuwerten, um Berichte über die Webseitenaktivitäten für Silverdust zu erstellen und um weitere, mit
                der Webseite- und der Internetnutzung verbundene Dienstleistungen zu erbringen. Google kann diese Daten
                gegebenenfalls an Dritte übertragen, sofern dies erforderlich oder gesetzlich vorgeschrieben ist, oder
                wenn Dritte diese Daten im Auftrag von Google verarbeiten. Google wird Deine IP-Adresse nicht mit
                anderen Daten von Google verknüpfen.{" "}
            </p>
            <p>
                Du hast weiterhin die Möglichkeit, die Erfassung der durch Cookies erzeugten und auf Deine Nutzung der
                Webseite bezogenen Daten (inkl. Ihrer IP-Adresse) durch Google und die Verarbeitung dieser Daten durch
                Google zu verhindern, indem Du ein von Google zur Verfügung gestelltes Browser-Plug-In herunterlädst und
                installierst.
            </p>
            <h2>An wen geben wir personenbezogene Daten weiter?</h2>
            <p>
                Eine Übermittlung Deiner persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten
                Gründen findet nicht statt. Wir geben Deine persönlichen Daten nur an Dritte weiter, wenn:
            </p>
            <ul>
                <li>Du uns ausdrücklich für die zugrundliegende Verarbeitung Deine Einwilligung gegeben hast,</li>
                <li>
                    dies gesetzlich zulässig und für die Abwicklung unserer Vertragsverhältnisse mit Dir erforderlich
                    ist,
                </li>
                <li>die Weitergabe der Daten auf einer gesetzlichen Verpflichtung beruht, sowie</li>
                <li>
                    die Weitergabe der Daten auf einem besonderen Interesse beruht und kein Grund zur Annahme besteht,
                    dass Du ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben.
                </li>
            </ul>
            <p>
                An folgende Empfänger bzw. Kategorien von Empfängern geben wir nach den vorher genannten Gründen Daten
                weiter:
            </p>
            <ul>
                <li>Mitarbeiter (in- und extern)</li>
                <li>IT-Infrastrukturdienstleister</li>
                <li>Zahlungsabwickler</li>
                <li>Dienstleister für Supportabwicklung</li>
                <li>Softwaredienstleister</li>
                <li>Anbietern von Analysetools</li>
                <li>Sonstige Dienstleister</li>
                <li>Öffentliche Institutionen</li>
            </ul>
            <h2>Wofür nutzen wir internationale Partner?</h2>
            <p>
                Wir nutzen zur Erbringung unserer Dienstleistungen ein weltweites Netzwerk von IT-Infrastruktur, wie
                z. B. Computer, cloudbasierte Server, Netzwerke und Softwarelösungen.
            </p>
            <p>
                Diese Partner sind in unterschiedlichen Ländern beheimatet, teilweise auch außerhalb der Europäischen
                Union. In diesen Ländern ist nicht immer das gleiche Datenschutzniveau rechtlich vorgegeben und
                etabliert, wie in der Europäischen Union. Von daher haben wir eine Reihe von Maßnahmen, nach Vorgaben
                der DSGVO ergriffen, um einen möglichst hohen Schutz Ihrer personenbezogenen Daten sicherzustellen.
                Dieses sind:
            </p>
            <ul>
                <li>
                    Kooperationen mit Unternehmen in einem Land, welches durch einen Angemessenheitsbeschluss der
                    Europäischen Union anerkannt wurde
                </li>
                <li>Kooperation mit Unternehmen nach dem EU-US-Privacy-Shield</li>
                <li>Kooperationen mit Unternehmen auf Basis der EU Standardvertragsklauseln</li>
                <li>Kooperationen mit Unternehmen auf Basis vereinbarter Garantien</li>
            </ul>
            <p>
                Diese Maßnahmen lassen wir uns im Rahmen der gesetzlichen Auflagen durch unsere Partner garantieren.
                Darüber hinaus besteht in besonderen Fällen die Möglichkeit, die Weitergabe der Daten auf Grund Ihrer
                ausdrücklichen Einwilligung durchzuführen.
            </p>
            <h2>Welche Einstellungen in Bezug auf den Datenschutz kannst Du vornehmen?</h2>
            <p>
                Du hast in unseren Produkten einige Wahlmöglichkeiten bzw. Einstellungsmöglichkeiten. Diese werden Dir
                in der Regel erklärt, wenn Du sie zum ersten Mal nutzt oder Dich dafür neu anmeldest. Es ist durchaus
                möglich, dass durch die Veränderung von Einstellungen gewisse Dienste und Services nicht mehr
                ordnungsgemäß funktionieren.
            </p>
            <h2>Wie kannst Du Deine Einwilligung widerrufen?</h2>
            <p>
                Wenn Du uns Deine Einwilligung zu bestimmten Datenverarbeitungen gegeben hast, z. B. den Empfang eines
                Newsletters, Unterbreitung von Angeboten Dritter, so hast Du jederzeit das Recht, diese Einwilligung –
                auch in Teilen – zu widerrufen. Bitte kontaktiere uns dazu. Sollte die Verarbeitung der Daten auf eine
                Interessensabwägung nach Art. 6 Abs. 1 lit f DSGVO erfolgen, so hast Du auch hier das Recht, Widerspruch
                gegen die Verarbeitung einzulegen, soweit dafür Gründe vorliegen, die sich aus Deiner besonderen
                Situation ergeben oder es sich um Direktwerbung handelt. Bei Direktwerbung hast Du ein generelles
                Widerspruchsrecht, ohne dass Angaben zur besonderen Situation nötig sind. Bitte teile uns dazu Deinen
                Widerspruch in Textform mit.
            </p>
            <h2>Welche Rechte hast Du?</h2>
            <p>
                Du hast, vorbehaltlich möglicher gesetzlicher Einschränkungen, folgende Rechte, die Du in Anspruch
                nehmen kannst: Das Recht auf Auskunft, Berichtigung, Löschung, Einschränkung der Verarbeitung,
                Datenübertragbarkeit und Widerspruch. Wir weisen an dieser Stelle ausdrücklich darauf hin, dass wir uns,
                wie gesetzlich gefordert, eine entsprechende Identifikation vorbehalten und ggf. auch weitere Maßnahmen
                zur eindeutigen Überprüfung der Identifikation vornehmen.
            </p>
            <h3>Recht auf Auskunft:</h3>
            <p>
                Wenn Du eine Auskunft über die von uns gespeicherten personenbezogenen Daten wünschst, so bitten wir
                Dich, uns zu kontaktieren
            </p>
            <h3>Recht auf Berichtigung:</h3>
            <p>
                Solltest Du feststellen, dass diese Informationen nicht richtig sind, so bitten wir Dich, uns Deine
                Berichtigungswünsche in Textform mitzuteilen.
            </p>
            <h3>Recht auf Löschung:</h3>
            <p>
                Solltest Du die Löschung Deiner Daten wünschen, werden wir entsprechend der gesetzlichen Vorgaben Deine
                Daten löschen. Wir weisen aber bereits an dieser Stelle darauf hin, dass wir verpflichtet sind aufgrund
                von gesetzlichen Bestimmungen festgelegte Daten auch länger zu speichern, wie z. B. die
                Aufbewahrungsfristen für buchhalterische Unterlagen von derzeit 10 Jahren (Abgabenordnung) oder aus
                Gewährleistungsgründen von 3 Jahren.
            </p>
            <p>
                Des Weiteren weisen wir daraufhin, dass wir Deine Daten zwar umgehend sperren, es auf Grund von
                technischen Einschränkungen aber bis zu 30 Tage dauern kann, bis wir die Daten endgültig gelöscht haben.
            </p>
            <p>
                Bitte beachte des Weiteren, dass nach der Bestätigung des Löschauftrages keine Möglichkeit mehr besteht,
                Deine Daten wiederherzustellen.
            </p>
            <h3>Recht auf Einschränkung der Verarbeitung:</h3>
            <p>
                Du hast das Recht, die Verarbeitung von Daten einzuschränken. Dazu teile uns bitte die aus Deiner Sicht
                betroffenen Datenkategorien und die Begründung für Dein Ersuchen mit. Wir werden den Sachverhalt
                umgehend prüfen und Dich über das Ergebnis informieren.
            </p>
            <h3>Recht auf Datenübertragbarkeit:</h3>
            <p>
                Bitte teile uns schriftlich mit, welche Daten Du an wen übertragen haben möchtest. Wir werden Dein
                Ersuchen umgehend prüfen und Dich über das Ergebnis informieren.
            </p>
            <h3>Beschwerderecht:</h3>
            <p>
                Falls Du mit unserer Arbeit in Zusammenhang mit dem Datenschutz unzufrieden bist, hast Du das Recht,
                Dich bei der für Dich in Deinem Land zuständigen Aufsichtsbehörde für den Datenschutz zu beschweren. Für
                die Silverdust GmbH ist dies z. B. Der Landesbeauftragte für den Datenschutz und die
                Informationsfreiheit Baden-Württemberg Postfach 10 29 32, 70025 Stuttgart Königstraße 10a, 70173
                Stuttgart zuständig.
            </p>
            <h2>Wie schützen wir personenbezogene Daten?</h2>
            <p>
                um Schutz Deiner personenbezogenen Daten hat Silverdust Maßnahmen getroffen, die dem Datenschutzrecht
                und dem Stand der Technik in der Branche entsprechen. Diese werden kontinuierlich überprüft und ggf.
                angepasst. Ziel ist es, Deine Daten gegen zufällige oder vorsätzliche Manipulationen, dem teilweisen
                oder vollständigen Verlust, der Zerstörung oder gegen unbefugte Zugriffe durch Dritte zu schützen.{" "}
            </p>
            <p>
                Zur Übertragung von Daten zwischen unseren Webseiten, unseren Applikationen und unseren Backends wird
                die Kommunikation nach dem SSL-Verfahren (Secure Socket Layer) verschlüsselt.{" "}
            </p>
            <p>
                Wir schützen die Systeme und Verarbeitungen durch eine Reihe von technischen und organisatorischen
                Maßnahmen. Dazu zählen u. a. die Datenverschlüsselung, die Pseudo- und Anonymisierung, logische und
                physische Zugangsbeschränkung und -kontrolle, Firewalls und Wiederherstellungssysteme, Integritätstest.
            </p>
            <p>
                Unsere Mitarbeiter werden auf den entsprechenden sensiblen Umgang mit personenbezogenen Daten regelmäßig
                geschult und entsprechend den rechtlichen Vorgaben auf die Einhaltung des Datengeheimnisses
                verpflichtet.
            </p>
            <h2>Welche Möglichkeiten bestehen für Minderjährige, unsere Dienste zu nutzen?</h2>
            <p>
                Unsere Produkte und Dienstleistungen dürfen nicht von Minderjährigen beauftragt und installiert werden.
            </p>
            <h2>Welche Informationen sind noch wichtig? </h2>
            <h3>Änderung der vorliegenden Datenschutzgrundsätze</h3>
            <p>
                Diese Datenschutzrichtlinie wird in unregelmäßig Abständen überarbeitet, um sie an die aktuellen
                Entwicklungen im Unternehmen, unsere Produkte und Dienstleistungen, den gesetzlichen Vorgaben und
                gesellschaftlichen Entwicklungen anzupassen.
            </p>
            <p>Stand: 24. November 2020</p>
        </section>
    );
};
