import React, { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { StoreForm } from "./store.form";
import { useStore } from "../../hooks/useStore.repository";
import { storeBlueprint } from "./store.blueprint";
import { Headline } from "../../layout";
import "./styles.css";

import { StoreDtoWithImages } from "../../entities/store.dto";
import { DataContext } from "../../context";

type Props = {};

export const StoreCreate: React.FC<Props> = (props): JSX.Element => {
    const { activeOrganisation } = useContext(DataContext);
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const { create } = useStore(id);

    async function handleSubmit(values: StoreDtoWithImages) {
        // const valuesWithImagesTransformedToIds =
        //     values.images && isType<Image[]>(values.images, "image")
        //         ? {
        //               ...values,
        //               images: values.images.map((image: Image) => image._id),
        //           }
        //         : values;

        await create(values);

        history.push("/settings/organisations");
    }

    return (
        <>
            <Headline title="Restaurant / Filiale anlegen" subtitle={activeOrganisation?.name} />
            <StoreForm organisationId={id} data={storeBlueprint} handleSubmit={handleSubmit} />
        </>
    );
};
