import React, { useContext } from "react";
import { AuthContext } from "../context";
import { isAllowed } from "./is-allowed";

type Props = {
    entityId?: string;
    requireAdmin?: boolean;
    message?: JSX.Element;
};

export const Authorized: React.FC<Props> = ({
    entityId,
    requireAdmin = false,
    message = undefined,
    children,
}): JSX.Element | null => {
    const { user } = useContext(AuthContext);

    if (requireAdmin) {
        return user?.isAdmin ? <>{children}</> : null;
    }

    const fallback = message ? message : null;

    return user && isAllowed(user, entityId) ? <>{children}</> : fallback;
};
