import React from "react";
import { Field, FieldProps } from "formik";
import { Calendar } from "primereact/calendar";
import { ConditionalSwitch } from "../conditional-switch";
import "./styles.css";

type Props = {
    field: FieldProps["field"];
    fieldName: string;
    workingDayLabel: string;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
};

export const WorkingDay: React.FC<Props> = ({ field, fieldName, workingDayLabel, setFieldValue }): JSX.Element => {
    function setAllNull() {
        setFieldValue(`${fieldName}.early.from`, null);
        setFieldValue(`${fieldName}.early.to`, null);
        setLateNull();
    }

    function setLateNull() {
        setFieldValue(`${fieldName}.late.from`, null);
        setFieldValue(`${fieldName}.late.to`, null);
    }

    return (
        <>
            <Field name={fieldName}>
                {({ field }: FieldProps) => {
                    let early = undefined,
                        late = undefined;

                    if (field.value) {
                        early = field.value.early;
                        late = field.value.late;
                    }

                    const earlyFrom = early?.from ? new Date(early.from) : undefined;
                    const earlyTo = early?.to ? new Date(early.to) : undefined;

                    const lateFrom = late?.from ? new Date(late.from) : undefined;
                    const lateTo = late?.to ? new Date(late.to) : undefined;

                    return (
                        <div className="working-day">
                            <label htmlFor={fieldName} className="working-day__label">
                                {workingDayLabel}
                            </label>

                            <ConditionalSwitch
                                show={early?.from && early?.to}
                                fallback={<span className="working-day__fallback">Ruhetag</span>}
                                onDeactivate={setAllNull}
                            >
                                <div className="working-day__times">
                                    <div className="working-day__time-picker-container">
                                        <div className="working-day__time-picker">
                                            <span className="working-day__time-picker-label">Von</span>
                                            <Calendar
                                                timeOnly
                                                name={`${fieldName}.from`}
                                                showTime
                                                hourFormat="24"
                                                value={earlyFrom}
                                                onChange={({ value }) => {
                                                    if (value instanceof Date) {
                                                        setFieldValue(`${fieldName}.early.from`, value);
                                                    }
                                                }}
                                            ></Calendar>
                                        </div>
                                        <div className="working-day__time-picker">
                                            <span className="working-day__time-picker-label">bis</span>
                                            <Calendar
                                                timeOnly
                                                name={`${fieldName}.to`}
                                                showTime
                                                hourFormat="24"
                                                value={earlyTo}
                                                onChange={({ value }) => {
                                                    if (value instanceof Date) {
                                                        setFieldValue(`${fieldName}.early.to`, value);
                                                    }
                                                }}
                                            ></Calendar>
                                        </div>
                                    </div>
                                </div>
                            </ConditionalSwitch>
                            {early?.from && early?.to && (
                                <ConditionalSwitch
                                    disabled={!early?.from || !early?.to}
                                    show={late?.from && late?.to}
                                    fallback={<span className="working-day__fallback">Abends hinzufügen</span>}
                                    onDeactivate={setLateNull}
                                >
                                    <div className="working-day__times">
                                        <div className="working-day__time-picker-container">
                                            <div className="working-day__time-picker">
                                                <span className="working-day__time-picker-label">Von</span>
                                                <Calendar
                                                    timeOnly
                                                    name={`${fieldName}.from`}
                                                    showTime
                                                    hourFormat="24"
                                                    value={lateFrom}
                                                    onChange={({ value }) => {
                                                        if (value instanceof Date) {
                                                            setFieldValue(`${fieldName}.late.from`, value);
                                                        }
                                                    }}
                                                ></Calendar>
                                            </div>
                                            <div className="working-day__time-picker">
                                                <span className="working-day__time-picker-label">bis</span>
                                                <Calendar
                                                    timeOnly
                                                    name={`${fieldName}.to`}
                                                    showTime
                                                    hourFormat="24"
                                                    value={lateTo}
                                                    onChange={({ value }) => {
                                                        if (value instanceof Date) {
                                                            setFieldValue(`${fieldName}.late.to`, value);
                                                        }
                                                    }}
                                                ></Calendar>
                                            </div>
                                        </div>
                                    </div>
                                </ConditionalSwitch>
                            )}
                        </div>
                    );
                }}
            </Field>
        </>
    );
};
