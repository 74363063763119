import { useContext } from "react";
import { useRequest } from "./use-request";

import { DispatchContext } from "../context/dispatch.context";
import { Organisation } from "../entities/organisation.entity";
import { StoreDto } from "../entities/store.dto";
import { PaginatedResult } from "../entities/paginated-result";

import { UiActions } from "../state/ui.reducer";
import { Store } from "../entities/store.entity";
import { StoresActions } from "../state/stores.reducer";
import { Offer } from "../entities/offer.entity";
import { makeToast } from "../utils/make-toast";

type ReturnType = {
    create: (data: StoreDto) => void;
    getAll: () => void;
    getOffers: ({
        startDate,
        endDate,
        includeNullDates,
        page,
    }: {
        startDate?: Date;
        endDate?: Date;
        includeNullDates?: boolean;
        page?: number;
    }) => Promise<PaginatedResult<Offer> | void>;
    get: (storeId: Store["_id"]) => Promise<Store | void>;
    update: (data: StoreDto, id: Store["_id"]) => void;
    remove: () => void;
};

export const useStore = (organisationId?: Organisation["_id"], id?: Store["_id"]): ReturnType => {
    const { dispatch } = useContext(DispatchContext);

    const { request } = useRequest();

    const getAll = async (): Promise<void> => {
        const response = await request<Store[]>({
            url: `/stores/?populate=images`,
            withCredentials: false,
        });

        if (response.status >= 400) {
            dispatch({
                type: UiActions.ADD_TOAST_MESSAGE,
                payload: makeToast({
                    severity: "error",
                    summary: "Fehler",
                    detail: "Stammdaten konnten nicht geladen werden",
                }),
            });
        } else {
            dispatch({
                type: StoresActions.STORES_RECEIVED,
                payload: { id: organisationId!, stores: response.data },
            });
        }
    };

    const getOffers = async ({
        startDate,
        endDate,
        includeNullDates,
        page,
    }: {
        startDate?: Date;
        endDate?: Date;
        includeNullDates?: boolean;
        page?: number;
    }): Promise<PaginatedResult<Offer> | void> => {
        if (id) {
            let query = ``;
            query += startDate ? `&startDate=${startDate}` : "";
            query += endDate ? `&endDate=${endDate}` : "";
            query += includeNullDates ? `&includeNullDates=${includeNullDates}` : "";
            query += page ? `&page=${page}` : "";

            const response = await request<PaginatedResult<Offer>>({
                url: `/stores/${id}/offers?populate=image${query}`,
                withCredentials: false,
            });

            if (response.status >= 400) {
                dispatch({
                    type: UiActions.ADD_TOAST_MESSAGE,
                    payload: {
                        severity: "error",
                        summary: "Fehler",
                        detail: "Angebote konnten nicht geladen werden",
                        life: 5000,
                    },
                });
            } else {
                return response.data;
            }
        }
    };

    const create = async (data: StoreDto): Promise<void> => {
        const response = await request<Store>({
            url: `/stores`,
            method: "POST",
            data,
        });

        if (response.status >= 400) {
            dispatch({
                type: UiActions.ADD_TOAST_MESSAGE,
                payload: makeToast({
                    severity: "error",
                    summary: "Fehler",
                    detail: "Filialdaten konnten nicht gespeichert werden",
                }),
            });
        } else {
            dispatch({
                type: StoresActions.STORE_CREATED,
                payload: {
                    id: response.data.organisation as string,
                    store: response.data,
                },
            });
        }
    };

    const get = async (storeId: Store["_id"]): Promise<Store | void> => {
        const response = await request<Store>({
            url: `/stores/${storeId}?populate=images`,
            withCredentials: false,
        });

        if (response.status >= 400) {
            dispatch({
                type: UiActions.ADD_TOAST_MESSAGE,
                payload: makeToast({
                    severity: "error",
                    summary: "Fehler",
                    detail: "Filialdaten konnten nicht geladen werden",
                }),
            });
        } else {
            return response.data;
        }
    };

    const update = async (data: StoreDto, id: Store["_id"]): Promise<Store | void> => {
        const response = await request<Store>({ url: `/stores/${id}`, method: "PATCH", data });

        if (response.status >= 400) {
            dispatch({
                type: UiActions.ADD_TOAST_MESSAGE,
                payload: makeToast({
                    severity: "error",
                    summary: "Fehler",
                    detail: "Filialdaten konnten nicht aktualisiert werden",
                }),
            });
        } else {
            dispatch({
                type: StoresActions.STORE_UPDATED,
                payload: {
                    id: response.data.organisation as string,
                    store: response.data,
                },
            });
        }
    };

    const remove = async () => {
        await request<undefined>({ url: `/stores/${id}`, method: "DELETE" });
        dispatch({ type: StoresActions.STORE_DELETED, payload: { id: organisationId!, storeId: id! } });
    };

    return { getAll, getOffers, create, get, update, remove };
};
