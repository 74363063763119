import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";

import { Bootstrap } from "./Bootstrap";

// import reportWebVitals from './reportWebVitals';
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./index.css";

// window.onpageshow = function (event: PageTransitionEvent) {
//     if (event.persisted) {
//         window.location.reload();
//     }
// };

render(
    <BrowserRouter>
        <Bootstrap />
    </BrowserRouter>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
