import { UiActionTypes, UiState, uiReducer, initialUiState } from "./ui.reducer";
import { AuthActionTypes, AuthState, authReducer, initialAuthState } from "./auth.reducer";
import { initialUserState, UserActionTypes, userReducer, UserState } from "./user.reducer";
import { ErrorActionTypes, errorReducer, ErrorState, initialErrorState } from "./error.reducer";
import { LoadingActionTypes, loadingReducer } from "./loading.reducer";
import { Organisation } from "../entities/organisation.entity";
import { ActiveOrganisationActionTypes, activeOrganisationReducer } from "./active-organisation.reducer";
import { OrganisationsActionTypes, initialOrganisationsState, organisationsReducer } from "./organisation.reducer";
import { initialStoresState, StoresActionTypes, storesReducer, StoreState } from "./stores.reducer";
import { initialOffersState, OffersActionTypes, offersReducer, OfferState } from "./offers.reducer";
import {
    initialAppSettingsState,
    AppSettingsActionTypes,
    appSettingsReducer,
    AppSettingsState,
} from "./app-settings.reducer";

export interface State {
    auth: AuthState;
    user: UserState;
    organisations: Organisation[];
    activeOrganisation: Organisation | undefined;
    stores: StoreState;
    offers: OfferState;
    ui: UiState;
    error: ErrorState;
    isLoading: boolean;
    appSettings: AppSettingsState;
}

export const state: State = {
    auth: initialAuthState,
    user: initialUserState,
    organisations: initialOrganisationsState,
    stores: initialStoresState,
    offers: initialOffersState,
    activeOrganisation: undefined,
    ui: initialUiState,
    error: initialErrorState,
    isLoading: false,
    appSettings: initialAppSettingsState,
};

export type Actions =
    | AuthActionTypes
    | UiActionTypes
    | UserActionTypes
    | ErrorActionTypes
    | LoadingActionTypes
    | ActiveOrganisationActionTypes
    | OrganisationsActionTypes
    | StoresActionTypes
    | OffersActionTypes
    | AppSettingsActionTypes;

export const rootReducer = (state: State, action: Actions): State => ({
    ui: uiReducer(state.ui, action as UiActionTypes),
    auth: authReducer(state.auth, action as AuthActionTypes),
    user: userReducer(state.user, action as UserActionTypes),
    organisations: organisationsReducer(state.organisations, action as OrganisationsActionTypes),
    stores: storesReducer(state.stores, action as StoresActionTypes),
    offers: offersReducer(state.offers, action as OffersActionTypes),
    activeOrganisation: activeOrganisationReducer(state.activeOrganisation, action as ActiveOrganisationActionTypes),
    error: errorReducer(state.error, action as ErrorActionTypes),
    isLoading: loadingReducer(state.isLoading, action as LoadingActionTypes),
    appSettings: appSettingsReducer(state.appSettings, action as AppSettingsActionTypes),
});

export const combinedInitialDataState: LocalDataState = {
    activeOrganisation: undefined,
    appSettings: {
        ...initialAppSettingsState,
    },
    offers: undefined,
    organisations: initialOrganisationsState,
    stores: undefined,
};

export interface LocalDataState {
    activeOrganisation: Organisation | undefined;
    appSettings: AppSettingsState;
    organisations: Organisation[];
    stores: StoreState;
    offers: OfferState;
}

// export type ActionMap<M extends { [index: string]: any }> = {
//     [K in keyof M]: M[K] extends undefined ? { type: K } : { type: K; payload: M[K] };
// };
