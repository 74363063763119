import React, { useContext, useEffect, useState } from "react";
import { Route } from "react-router-dom";
import axios from "axios";

import { RequireAuth } from "../components/hoc/require-auth";
import { WithUser } from "../components/hoc/with-user";
import { Layout } from "../layout";

import { State } from "../state/root.reducer";
import { Dashboard } from "./dashboard/dashboard.page";
import { Feedback } from "./admin/feedback/feedback";
import { SettingsIndex } from "./settings/settings.index";
import { AdminRoutes } from "./admin/admin.routes";
import { OrganisationRoutes } from "./organisations/organisation.routes";
import { StoreRoutes } from "./stores/store.routes";
import { OfferRoutes } from "./offers/offer.routes";
import { UserProfileRoutes } from "./user-profile/user-profile.routes";
import { AuthContext, DispatchContext } from "../context";
import { useOrganisation } from "../hooks/useOrganisation.repository";
import { AppSettingsActions } from "../state/app-settings.reducer";

type Props = {
    state: State;
};

export const ProtectedRoutes: React.FC<Props> = ({ state }): JSX.Element => {
    const [loading, setLoading] = useState<Boolean>(true);

    const { getAll: getOrganisations } = useOrganisation();
    const { user } = useContext(AuthContext);
    const { dispatch } = useContext(DispatchContext);

    useEffect(() => {
        (async () => {
            // prepopulate for better UX...
            await getOrganisations();
            window.setTimeout(() => setLoading(false), 300);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const response = await axios({ url: `/settings/attributes` });
            dispatch({
                type: AppSettingsActions.RECEIVE_SETTINGS,
                payload: {
                    cuisines: response.data.cuisines,
                    foodTypes: response.data.foodTypes,
                },
            });
        })();
    }, [user?._id]);

    return (
        <RequireAuth>
            <WithUser userId={state.auth?.id} token={state.auth?.token}>
                <Layout>
                    <AdminRoutes mountPath="/admin" />
                    <OrganisationRoutes mountPath="/settings" />
                    <StoreRoutes mountPath="/settings" />
                    <UserProfileRoutes mountPath="/settings" />
                    <OfferRoutes />
                    <Route exact path="/feedback">
                        <Feedback />
                    </Route>
                    <Route exact path="/settings">
                        <SettingsIndex />
                    </Route>
                    <Route exact path="/">
                        <Dashboard loading={loading} />
                    </Route>
                </Layout>
            </WithUser>
        </RequireAuth>
    );
};
