import React, { useContext, useEffect } from "react";
import { useUser } from "../../hooks/useUser.repository";
import { AuthContext } from "../../context";

export const UserProfile: React.FC = (): JSX.Element => {
    const { auth, user } = useContext(AuthContext);
    const { getUser } = useUser();

    useEffect(() => {
        (async () => {
            if (auth?.id) {
                await getUser(auth?.id);
            }
        })();
    }, [auth?.id]);

    return <>{user?.email}</>;
};
