import React from "react";
import { AuthContext } from "../../context";
import { AuthAndUserState } from "../../context/auth.context";

// type UserAndOrgaProps = {
//     user: User | null | undefined;
//     organisation?: Organisation;
// };

export function WithAuthorizationContext<P>(WrappedComponent: React.ComponentType<P & Partial<AuthAndUserState>>) {
    const ComponentWithExtraInfo = (props: P): JSX.Element => {
        return <AuthContext.Consumer>{(value) => <WrappedComponent {...props} {...value} />}</AuthContext.Consumer>;
    };

    return ComponentWithExtraInfo;
}
