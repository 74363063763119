import React from "react";
import { Route, Switch } from "react-router-dom";
import { Authorized } from "../../access-control/authorized";
import { AdminOrganisationsIndex } from "./organisations";

type Props = {
    mountPath?: string;
};

export const AdminRoutes: React.FC<Props> = ({ mountPath = "" }): JSX.Element => {
    return (
        <Route>
            <Authorized requireAdmin message={<div>Zugriff nicht erlaubt</div>}>
                <Switch>
                    <Route path={`${mountPath}/organisations/`}>
                        <AdminOrganisationsIndex />
                    </Route>
                </Switch>
            </Authorized>
        </Route>
    );
};
