import { StoreDtoWithImages } from "../../entities/store.dto";

export let storeBlueprint: StoreDtoWithImages = {
    name: "",
    images: null,
    description: "",
    address: {
        street: "",
        zip: "",
        city: "",
    },
    location: {
        coordinates: ["", ""],
    },
    workingDays: {
        monday: { early: { from: null, to: null }, late: { from: null, to: null } },
        tuesday: { early: { from: null, to: null }, late: { from: null, to: null } },
        wednesday: { early: { from: null, to: null }, late: { from: null, to: null } },
        thursday: { early: { from: null, to: null }, late: { from: null, to: null } },
        friday: { early: { from: null, to: null }, late: { from: null, to: null } },
        saturday: { early: { from: null, to: null }, late: { from: null, to: null } },
        sunday: { early: { from: null, to: null }, late: { from: null, to: null } },
    },
    services: {
        deliveryService: false,
        takeAwayService: false,
        eatInService: false,
    },
    email: "",
    phone: "",
    website: "",
    cuisines: [""],
};
