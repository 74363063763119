import React from "react";
import { Route, Switch } from "react-router-dom";
import { StoreCreate } from "./store.create";
import { StoreEdit } from "./store.edit";
import { StoreIndex } from "./store.index";

type Props = {
    mountPath?: string;
};

export const StoreRoutes: React.FC<Props> = ({ mountPath = "" }): JSX.Element => {
    return (
        <Route>
            <Switch>
                <Route path={`${mountPath}/stores/create`}>
                    <StoreCreate />
                </Route>
                <Route path={`${mountPath}/stores/:id`}>
                    <StoreEdit />
                </Route>
                <Route exact path={`${mountPath}/stores`}>
                    <StoreIndex />
                </Route>
            </Switch>
        </Route>
    );
};
