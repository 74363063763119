import React from "react";
import { Dialog as PrimeReactDialog } from "primereact/dialog";
import { Button } from "primereact/button";

type Props = {
    onHide: () => void;
    onConfirm: () => void;
    visible: boolean;
    confirmLabel: string;
    denyLabel?: string;
};

export const Dialog: React.FC<Props> = ({
    children,
    onHide = () => {},
    visible,
    onConfirm,
    confirmLabel,
    denyLabel,
}): JSX.Element => {
    const footer = (
        <div>
            <Button label={confirmLabel} icon="pi pi-check" onClick={onConfirm} />
            {denyLabel && <Button label={denyLabel} icon="pi pi-times" onClick={onHide} />}
        </div>
    );

    return (
        <PrimeReactDialog visible={visible} onHide={onHide} footer={footer}>
            {children}
        </PrimeReactDialog>
    );
};
