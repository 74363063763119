import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Organisation } from "../../entities/organisation.entity";
import { useOrganisation } from "../../hooks/useOrganisation.repository";
import { Store } from "../../entities/store.entity";
import { Authorized } from "../../access-control/authorized";

const { REACT_APP_APP_URL: APP_URL } = process.env;

type Props = {
    organisation: Organisation;
    stores: Store[];
    path: string;
};

export const OrganisationDetail: React.FC<Props> = ({ organisation, stores, path }): JSX.Element => {
    const { getStores } = useOrganisation(organisation._id);

    useEffect(() => {
        (async () => {
            await getStores();
        })();
    }, []);

    // const image =
    //     organisation.image && isType<Image>(organisation.image, "image")
    //         ? `${API_URL}/${organisation.image?.renditions?.large?.path}`
    //         : undefined;

    const {
        name,
        address: { street, zip, city },
        phone,
        email,
    } = organisation;

    return (
        <>
            <div className="infotable-heading">
                <h2>{name}</h2>
                <Authorized entityId={organisation._id}>
                    <Link to={`${path}/${organisation._id}`}>
                        <i className="pi pi-pencil"></i>
                    </Link>
                </Authorized>
            </div>

            <table className="infotable">
                <tbody>
                    <tr>
                        <th>Adresse</th>
                        <td>
                            {street}, {zip} {city}
                        </td>
                    </tr>
                    <tr>
                        <th>Telefon</th>
                        <td>{phone}</td>
                    </tr>
                    <tr>
                        <th>E-Mail</th>
                        <td>{email}</td>
                    </tr>
                </tbody>
            </table>
            {stores && stores.length > 0 && (
                <div style={{ marginBottom: "40px" }}>
                    <h2>Filialen</h2>
                    <table className="infotable">
                        <tbody>
                            {stores.map((store: Store) => {
                                const {
                                    address: { street, zip, city },
                                } = store;
                                return (
                                    <tr key={store._id}>
                                        <th>
                                            {store.name}{" "}
                                            <a
                                                href={`${APP_URL}/gastro/${store?.slug}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i
                                                    className="pi pi-external-link p-ml-1"
                                                    style={{ fontSize: ".8em" }}
                                                ></i>
                                            </a>
                                            <br />
                                            <span style={{ fontWeight: "normal" }}>
                                                {street}, {zip} {city}
                                            </span>
                                            <br />
                                            <Link style={{ fontWeight: "normal" }} to={`/store/${store._id}/offers`}>
                                                Angebote pflegen
                                            </Link>
                                        </th>
                                        <td>
                                            <Authorized entityId={organisation._id}>
                                                <Link
                                                    to={`/settings/organisations/${organisation._id}/store/${store._id}`}
                                                >
                                                    Filiale bearbeiten
                                                </Link>
                                            </Authorized>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}

            <Link to={`/settings/organisations/${organisation?._id}/stores/create`} className="p-button p-component">
                Restaurant / Filiale für {organisation.name} anlegen
            </Link>
        </>
    );
};
