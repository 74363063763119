import { Offer } from "../entities/offer.entity";

export enum OffersActions {
    OFFERS_RECEIVED = "OFFERS_RECEIVED",
    OFFER_CREATED = "OFFER_CREATED",
    OFFER_UPDATED = "OFFER_UPDATED",
    OFFER_DELETED = "OFFER_DELETED",
}

export type OfferState = { [index: string]: Offer[] } | undefined;

export type OffersActionTypes =
    | { type: OffersActions.OFFERS_RECEIVED; payload: { id: string; offers: Offer[] } }
    | { type: OffersActions.OFFER_CREATED; payload: { id: string; offer: Offer } }
    | { type: OffersActions.OFFER_UPDATED; payload: { id: string; offer: Offer } }
    | { type: OffersActions.OFFER_DELETED; payload: { id: string; offerId: string } };

export const initialOffersState = undefined;

export const offersReducer = (state: OfferState = undefined, action: OffersActionTypes): OfferState => {
    switch (action.type) {
        case OffersActions.OFFERS_RECEIVED:
            return {
                ...state,
                [action.payload.id]: action.payload.offers,
            };

        case OffersActions.OFFER_CREATED:
            const storeId = action.payload.id;
            const newOffer = action.payload.offer;

            return {
                ...state,
                [storeId]: state && state[storeId] ? [...state[storeId], newOffer] : [newOffer],
            };

        case OffersActions.OFFER_UPDATED:
            return {
                ...state,
                [action.payload.id]:
                    state && state[action.payload.id]
                        ? state[action.payload.id].map((offer: Offer) =>
                              offer._id === action.payload.offer._id ? action.payload.offer : offer
                          )
                        : [action.payload.offer],
            };

        case OffersActions.OFFER_DELETED:
            return {
                ...state,
                [action.payload.id]:
                    state && state[action.payload.id]
                        ? state[action.payload.id].filter((offer: Offer) => offer._id !== action.payload.offerId)
                        : state![action.payload.id],
            };

        default:
            return state;
    }
};
