import { UserState } from "./user.reducer";

export enum AuthActions {
    USER_SIGNUP_SUCCESSFULL = "USER_SIGNUP_SUCCESSFULL",
    SET_AUTH = "SET_AUTH",
    LOGIN_USER = "LOGIN_USER",
    LOGOUT_USER = "LOGOUT_USER",
}

export type AuthActionTypes =
    | { type: AuthActions.LOGIN_USER; payload: LoginState }
    | { type: AuthActions.SET_AUTH; payload: AuthState }
    | { type: AuthActions.USER_SIGNUP_SUCCESSFULL; payload: AuthState }
    | { type: AuthActions.LOGOUT_USER };

export interface AuthState {
    id: string | undefined;
    email: string | undefined;
    token: string | undefined;
}

export interface LoginState {
    auth: AuthState;
    user: UserState;
}

export const initialAuthState = {
    id: undefined,
    email: undefined,
    token: undefined,
};

export const authReducer = (state: AuthState, action: AuthActionTypes): AuthState => {
    switch (action.type) {
        // case AuthActions.USER_SIGNUP_SUCCESSFULL:
        //     return action.payload;

        case AuthActions.LOGIN_USER:
            return action.payload.auth;

        case AuthActions.LOGOUT_USER:
            return { id: undefined, email: undefined, token: undefined };

        default:
            return state;
    }
};
