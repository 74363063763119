import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";

type Props = {};

export const Footer: React.FC<Props> = (props): JSX.Element | null => {
    return (
        <footer style={{ display: "flex", justifyContent: "center" }}>
            <ul className="footer-links">
                <li>
                    <Link to="/impressum">Impressum</Link>
                </li>
                <li>
                    <Link to="/datenschutz">Datenschutz</Link>
                </li>
                <li>
                    <Link to="/agb">AGB</Link>
                </li>
            </ul>
        </footer>
    );
};
