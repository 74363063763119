import { Dispatch } from "react";
import axios, { AxiosResponse } from "axios";
import { Actions } from "../state/root.reducer";
import { History } from "history";
import { AuthActions } from "../state/auth.reducer";

const refreshJWT = async (oldToken: string): Promise<AxiosResponse> =>
    await axios({
        url: "/auth/token/refresh",
        method: "POST",
        withCredentials: true,
        data: { jwt: oldToken },
    });

export const setupAxiosInterceptors = (dispatch: Dispatch<Actions>, history: History): void => {
    axios.interceptors.response.use(
        (res: AxiosResponse<any>) => res,
        async (error) => {
            if (error.config.url.startsWith("/auth/") && error.response.status === 401) {
                if (localStorage.getItem("auth")) {
                    dispatch({
                        type: AuthActions.LOGOUT_USER,
                    });
                }

                localStorage.removeItem("auth");
                localStorage.removeItem("user");
                localStorage.removeItem("appData");
                history.push("/login?logout=1");
                // force logout on mobile, to prevent browser back button usage
                window.location.reload();
            }

            const localAuth = localStorage.getItem("auth");

            if (error.response?.status === 401 && localAuth && JSON.parse(localAuth).token) {
                const { token } = JSON.parse(localAuth);
                const refreshedToken = await refreshJWT(token);

                if (refreshedToken.data.id && refreshedToken.data.email && refreshedToken.data.token) {
                    localStorage.setItem("auth", JSON.stringify(refreshedToken.data));
                    axios.defaults.headers.common["Authorization"] = `Bearer ${refreshedToken.data.token}`;

                    const { url, withCredentials, data, method, headers } = error.config;

                    const formData = data instanceof FormData ? data : null;
                    const payload = typeof data === "string" ? JSON.parse(data) : null;

                    const response = await axios({
                        url,
                        method,
                        withCredentials,
                        data: formData || payload,
                        headers: {
                            ...headers,
                            "Authorization": `Bearer ${refreshedToken.data.token}`,
                        },
                    });

                    return response;
                } else {
                    return error.response;
                }
            } else {
                return error.response;
            }
        }
    );

    axios.interceptors.response.use(
        (res: AxiosResponse<any>) => res,
        (err: any) => {
            console.log(err);
            throw err;
        }
    );

    // axios.interceptors.response.use((res: AxiosResponse<any>) => {
    //     if (res?.status === 400 || res?.status > 401) {
    //         throw res;
    //     } else {
    //         return res;
    //     }
    // });
};
