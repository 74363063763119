import { object, string } from "yup";

export const organisationValidationSchema = object({
    name: string().required("Bitte geben Sie Ihrer Firma einen Namen"),
    address: object({
        street: string().required("Bitte geben Sie Straße und Hausnr. an"),
        zip: string().required("Bitte geben Sie die Postleitzahl an"),
        city: string().required("Bitte geben Sie einen Ort an"),
    }),
    email: string()
        .email("Bitte geben Sie eine gültige E-Mail-Adresse an")
        .required("Bitte geben Sie eine Kontakt-E-Mail-Adresse an"),
    phone: string().required("Bitte geben Sie eine Telefonnummer an"),
});
