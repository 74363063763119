import React, { createRef, lazy, Suspense, useContext, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";

import { OfferTable } from "./offer-table";
import { AuthContext, DataContext } from "../../context";
import { useParams } from "react-router-dom";
import { useOffer } from "../../hooks/useOffer.repository";
import { useStore } from "../../hooks/useStore.repository";
import { Store } from "../../entities/store.entity";
import { offerBlueprint } from "./offer.blueprint";
import { Offer } from "../../entities/offer.entity";
import { Button } from "primereact/button";
import { OfferDto } from "../../entities/offer.dto";
import { Dialog as DeleteDialog } from "../../layout";

// import { SplitButton } from "primereact/splitbutton";

import "./styles.css";
import { Authorized } from "../../access-control/authorized";
import { PaginatedResult } from "../../entities/paginated-result";

const { REACT_APP_APP_URL: APP_URL } = process.env;

type Props = {};

const LazyOfferForm = lazy(() => import("./offer.form"));

// https://primefaces.org/primereact/showcase/#/datatable/crud
export const OfferDetail: React.FC<Props> = (props): JSX.Element => {
    let dataTableRef = createRef<DataTable>();
    const [initialValues, setInitialValues] = useState<Offer>();
    const [store, setStore] = useState<Store>();
    const [newOfferVisible, setNewOfferVisible] = useState<boolean>(false);
    const [editOfferVisible, setEditOfferVisible] = useState<boolean>(false);
    const [offerToDelete, setOfferToDelete] = useState<Offer["_id"]>("");
    const [comingOffers, setComingOffers] = useState<PaginatedResult<Offer>>();
    const [pastOffers, setPastOffers] = useState<PaginatedResult<Offer>>();

    // hack to trigger a reload, when an offer has been deleted, updated or created
    const [incrementToReload, setIncrementToReload] = useState<number>(0);

    const { user } = useContext(AuthContext);
    const { id } = useParams<{ id: string }>();
    const { activeOrganisation } = useContext(DataContext);
    const { create, update, remove, getOffers } = useOffer(id);
    const { get } = useStore(id);

    // const storeOffers = getOffersFromState(id, offers).sort(sortOffersByDate);

    async function getPagedOffers(queryParams: {}, updaterFunction: typeof setComingOffers | typeof setPastOffers) {
        const offers = await getOffers(id, queryParams);
        if (offers) {
            updaterFunction(offers);
        }
    }

    useEffect(() => {
        (async () => {
            const store = await get(id);
            if (store) {
                setStore(store);
            }
        })();
    }, [id]);

    useEffect(() => {
        getPagedOffers({ startDate: new Date(), page: comingOffers?.page || 1 }, setComingOffers);
    }, [id, incrementToReload]);

    useEffect(() => {
        getPagedOffers(
            {
                endDate: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 1),
                includeNullDates: false,
                page: pastOffers?.page || 1,
            },
            setPastOffers
        );
    }, [id, incrementToReload]);

    function ActionButtons(offer: Offer): JSX.Element {
        // const items = [
        //     {
        //         label: "Bearbeiten",
        //         icon: "pi pi-pencil",
        //         command: (e: { item: any; event: MouseEvent }) => {
        //             setInitialValues(offer);
        //             setEditOfferVisible(true);
        //         },
        //     },
        //     {
        //         label: "Kopieren",
        //         icon: "pi pi-copy",
        //         command: (e: { item: any; event: MouseEvent }) => {
        //             const { _id, ...offerWithoutId } = offer;
        //             const copiedOffer = {
        //                 ...offerWithoutId,
        //                 date: offer.date
        //                     ? new Date(new Date(offer.date).getTime() + 1000 * 60 * 60 * 24 * 7) /* in one week */
        //                     : new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 1) /* tomorrow */,
        //             };
        //             setInitialValues(copiedOffer);
        //             setEditOfferVisible(true);
        //         },
        //     },
        //     {
        //         label: "Anschauen",
        //         icon: "pi pi-external-link",
        //         command: (e: { item: any; event: MouseEvent }) => {
        //             window.open(`${APP_URL}/gastro/${store?.slug}#${offer._id}`, "_blank");
        //         },
        //     },
        // ];

        // if (user?.isAdmin) {
        //     items.push({
        //         label: "Löschen",
        //         icon: "pi pi-trash",
        //         command: (e: { item: any; event: MouseEvent }) => {
        //             setOfferToDelete(offer._id);
        //         },
        //     });
        // }

        // return (
        //     <SplitButton
        //         label="Bearbeiten"
        //         icon="pi pi-pencil"
        //         onClick={(event: MouseEvent) => {
        //             setInitialValues(offer);
        //             setEditOfferVisible(true);
        //         }}
        //         model={items}
        //     />
        // );

        return (
            <>
                <Button
                    icon="pi pi-external-link"
                    className="p-button-rounded p-button-secondary p-mt-1 p-mr-1"
                    onClick={() => window.open(`${APP_URL}/gastro/${store?.slug}#${offer._id}`, "_blank")}
                />
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-success p-mt-1 p-mr-1"
                    onClick={() => {
                        setInitialValues(offer);
                        setEditOfferVisible(true);
                    }}
                />
                <Button
                    icon="pi pi-copy"
                    className="p-button-rounded p-button-secondary p-mt-1 p-mr-1"
                    onClick={() => {
                        const { _id, ...offerWithoutId } = offer;
                        const copiedOffer = {
                            ...offerWithoutId,
                            date: offer.date
                                ? new Date(new Date(offer.date).getTime() + 1000 * 60 * 60 * 24 * 7) /* in one week */
                                : new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 1) /* tomorrow */,
                        };
                        setInitialValues(copiedOffer);
                        setEditOfferVisible(true);
                    }}
                />
                {store?.organisation && (
                    <Authorized entityId={store?.organisation as string}>
                        <Button
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-danger p-mt-1 p-mr-1"
                            onClick={() => setOfferToDelete(offer._id)}
                        />
                    </Authorized>
                )}
            </>
        );
    }

    return (
        <Authorized entityId={store?.organisation as string} message={<div>Zugriff nicht gestattet</div>}>
            <div className="datatable-crud datatable-responsive">
                <h1>{store && store.name}</h1>

                <div className="card">
                    {comingOffers && (
                        <OfferTable
                            dataTableRef={dataTableRef}
                            offers={comingOffers?.docs || []}
                            store={store}
                            actionButtons={ActionButtons}
                            heading="Kommende Angebote"
                            pagingInfo={comingOffers}
                            onPage={({ page }) =>
                                getPagedOffers({ startDate: new Date(), page: page + 1 }, setComingOffers)
                            }
                        />
                    )}

                    <div className="p-d-flex p-jc-end p-mt-4">
                        <Button
                            label="Angebot anlegen"
                            icon="pi pi-plus"
                            className="p-shadow-8 add-offer-button"
                            onClick={() => setNewOfferVisible(true)}
                        />
                    </div>
                </div>

                {pastOffers && pastOffers?.docs.length > 0 && (
                    <div className="card" style={{ marginTop: "100px" }}>
                        <OfferTable
                            dataTableRef={dataTableRef}
                            offers={pastOffers?.docs || []}
                            store={store}
                            actionButtons={ActionButtons}
                            heading="Vergangene Angebote"
                            pagingInfo={pastOffers}
                            onPage={({ page }) =>
                                getPagedOffers({ endDate: new Date(), page: page + 1 }, setPastOffers)
                            }
                        />
                    </div>
                )}

                {store && (
                    <>
                        <Dialog
                            visible={newOfferVisible}
                            style={{ width: "768px" }}
                            header="Neues Angebot anlegen"
                            modal
                            maximizable
                            className="p-fluid"
                            onHide={() => setNewOfferVisible(false)}
                        >
                            <Suspense fallback={<div>Lädt...</div>}>
                                <LazyOfferForm
                                    store={store}
                                    organisation={store.organisation as string}
                                    initialFormValues={offerBlueprint}
                                    abort={() => setNewOfferVisible(false)}
                                    handleSubmit={async (values) => {
                                        await create(values);
                                        setNewOfferVisible(false);
                                        setIncrementToReload((before) => before + 1);
                                    }}
                                />
                            </Suspense>
                        </Dialog>

                        {initialValues && (
                            <Dialog
                                visible={editOfferVisible}
                                style={{ width: "768px" }}
                                header="Angebot bearbeiten"
                                modal
                                maximizable
                                className="p-fluid"
                                // footer={ModalFooter()}
                                onHide={() => setEditOfferVisible(false)}
                            >
                                <Suspense fallback={<div>Lädt</div>}>
                                    <LazyOfferForm
                                        store={store}
                                        organisation={store.organisation as string}
                                        initialFormValues={initialValues as OfferDto}
                                        abort={() => setEditOfferVisible(false)}
                                        handleSubmit={async (values) => {
                                            if (initialValues._id) {
                                                await update(values, initialValues._id);
                                            } else {
                                                await create(values);
                                            }
                                            setEditOfferVisible(false);
                                            setIncrementToReload((before) => before + 1);
                                        }}
                                    />
                                </Suspense>
                            </Dialog>
                        )}
                    </>
                )}

                <DeleteDialog
                    onHide={() => setOfferToDelete("")}
                    onConfirm={async () => {
                        const deletedId = await remove(offerToDelete);
                        setOfferToDelete("");
                        setIncrementToReload((before) => before + 1);
                    }}
                    visible={offerToDelete ? true : false}
                    confirmLabel="Angebot löschen"
                >
                    Wollen Sie das Angebot wirklich löschen?
                </DeleteDialog>
            </div>
        </Authorized>
    );
};
