import React from "react";
import { Route, Switch } from "react-router-dom";
import { StoreCreate } from "../stores/store.create";
import { StoreEdit } from "../stores/store.edit";
import { OrganisationCreate } from "./organisation.create";
import { OrganisationEdit } from "./organisation.edit";
import { OrganisationsIndex } from "./organisations.index";

type Props = {
    mountPath?: string;
};

export const OrganisationRoutes: React.FC<Props> = ({ mountPath = "" }): JSX.Element => {
    return (
        <Route>
            <Switch>
                <Route path={`${mountPath}/organisations/create`}>
                    <OrganisationCreate />
                </Route>
                <Route path={`${mountPath}/organisations/:id/stores/create`}>
                    <StoreCreate />
                </Route>
                <Route path={`${mountPath}/organisations/:organisationId/store/:storeId`}>
                    <StoreEdit />
                </Route>
                <Route path={`${mountPath}/organisations/:organisationId`}>
                    <OrganisationEdit />
                </Route>
                <Route path={`${mountPath}/organisations`}>
                    <OrganisationsIndex />
                </Route>
            </Switch>
        </Route>
    );
};
