import { createContext } from "react";
import { Organisation } from "../entities/organisation.entity";
import { StoreState } from "../state/stores.reducer";
import { OfferState } from "../state/offers.reducer";
import { AppSettingsState } from "../state/app-settings.reducer";

interface Data {
    organisations: Organisation[];
    activeOrganisation: Organisation | undefined;
    stores: StoreState;
    offers: OfferState;
    appSettings: AppSettingsState;
}

export const DataContext = createContext<Partial<Data>>({});
