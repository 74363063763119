import React, { ChangeEvent, FormEvent, useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { useQuery } from "../../hooks/use-query";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Message } from "primereact/message";
import { UiActions } from "../../state/ui.reducer";
import { DispatchContext } from "../../context";

type Props = {};

export const PasswordReset: React.FC<Props> = (props): JSX.Element => {
    const { dispatch } = useContext(DispatchContext);
    const history = useHistory();
    const query = useQuery();
    const email = query.get("email");
    const token = query.get("token");

    const [password, setPassword] = useState<string>("");
    const [repeatedPassword, setRepeatedPassword] = useState<string>("");
    const [message] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    async function handleSubmit(e: FormEvent) {
        e.preventDefault();
        if (password !== repeatedPassword) {
            return setErrorMessage("Die Passwörter stimmen nicht überein.");
        }

        setLoading(true);

        try {
            const response = await axios({
                url: `auth/reset-password?email=${email}&token=${token}`,
                method: "POST",
                data: { email, password, repeatedPassword },
            });

            if (response.status >= 400) {
                setErrorMessage(response.data.message);
                setLoading(false);
            } else {
                dispatch({
                    type: UiActions.ADD_TOAST_MESSAGE,
                    payload: {
                        severity: "success",
                        summary: "Passwort geändert",
                        detail:
                            "Ihr Passwort wurde erfolgreich geändert. Sie können sich jetzt mit dem neuen Passwort einloggen",
                        life: 5000,
                    },
                });
                history.push("/login");
            }
        } catch (error) {
            setErrorMessage(error.data.message);
            setLoading(false);
        }
    }

    return (
        <div className="signup-login-page p-d-flex p-jc-center p-ai-start p-mt-6">
            <Card className="p-col-12 p-md-8 p-lg-6">
                <header className="p-d-flex p-flex-row p-flex-nowrap p-ai-center p-mb-4">
                    <img
                        src="/android-chrome-512x512.png"
                        style={{ width: "60px" }}
                        alt="tagesessen.com Logo"
                        className="logo"
                    />
                    <h1 className="p-mt-0 p-my-0 p-ml-2">tagesessen.com</h1>
                </header>
                <h1>Neues Passwort setzen</h1>
                {errorMessage && (
                    <div className="p-grid">
                        <div className="p-col-12">
                            <Message severity="error" text={errorMessage}></Message>
                        </div>
                    </div>
                )}
                {/* {message && <p className="message">{message}</p>} */}

                {!message && (
                    <div className="p-fluid">
                        <form onSubmit={handleSubmit}>
                            <div className="p-field">
                                <label htmlFor="password">Neues Passwort</label>
                                <InputText
                                    type="password"
                                    name="password"
                                    value={password}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                                    disabled={loading}
                                />
                            </div>
                            <div className="p-field">
                                <label htmlFor="repeated-password">Neues Passwort wiederholen</label>
                                <InputText
                                    type="password"
                                    name="repeated-password"
                                    value={repeatedPassword}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setRepeatedPassword(e.target.value)}
                                    disabled={loading}
                                />
                            </div>
                            <div className="p-field p-d-inline-flex">
                                <Button type="submit" label="Passwort ändern" disabled={loading} />
                            </div>
                        </form>
                    </div>
                )}
                <Link to="/login" className="form-link">
                    Zum Login
                </Link>
            </Card>
        </div>
    );
};
