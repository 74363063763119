import React from "react";
import { ProgressSpinner } from "primereact/progressspinner";

import "./styles.css";

type Props = {};

export const Spinner: React.FC<Props> = (): JSX.Element => {
    return <ProgressSpinner />;
};
