import React, { ChangeEvent, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useUser } from "../../hooks/useUser.repository";

const RequestPasswordResetForm = () => {
    const [email, setEmail] = useState<string>("");

    const { requestPasswordReset } = useUser();

    return (
        <>
            <div className="p-fluid">
                <form
                    onSubmit={async (e) => {
                        e.preventDefault();
                        await requestPasswordReset(email);
                    }}
                >
                    <div className="p-field">
                        <label htmlFor="email">E-Mail</label>
                        <InputText
                            name="email"
                            id="email"
                            type="email"
                            value={email}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="p-field p-d-inline-flex">
                        <Button>Link anfordern</Button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default RequestPasswordResetForm;
