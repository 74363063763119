import { Cuisine, FoodType } from "../entities/offer.dto";
import { AuthActions } from "./auth.reducer";

export enum AppSettingsActions {
    RECEIVE_SETTINGS = "RECEIVE_SETTINGS",
}

export type AppSettingsActionTypes =
    | { type: AppSettingsActions.RECEIVE_SETTINGS; payload: AppSettingsState }
    | { type: AuthActions.LOGOUT_USER };

export interface AppSettingsState {
    cuisines: Cuisine[];
    foodTypes: FoodType[];
}

export const initialAppSettingsState = {
    cuisines: [],
    foodTypes: [],
};

export const appSettingsReducer = (
    state: AppSettingsState = initialAppSettingsState,
    action: AppSettingsActionTypes
): AppSettingsState => {
    switch (action.type) {
        case AppSettingsActions.RECEIVE_SETTINGS:
            return action.payload;

        case AuthActions.LOGOUT_USER:
            return initialAppSettingsState;

        default:
            return state;
    }
};
