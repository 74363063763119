import React, { useEffect, Dispatch } from "react";
import { Route, Switch } from "react-router-dom";
import axios from "axios";

import { UiContext, AuthContext, DataContext } from "./context";
import { ProtectedRoutes } from "./features/protected.routes";
import { SignUpLoginPage } from "./features/login-signup/login-signup.page";
import { PasswordReset } from "./features/login-signup/password-reset";
import { ActivationFailed } from "./features/login-signup/activation-failed";
import { Impressum, DSE, AGB } from "./features/legal";
import { ToastArea, Footer, Layout } from "./layout";
import { useAxiosSetup } from "./hooks/use-axios-setup";
import { useLocalStorage } from "./hooks/use-localstorage";
import { AuthState, initialAuthState } from "./state/auth.reducer";
import { Actions, combinedInitialDataState, LocalDataState, State } from "./state/root.reducer";
import { useHydrateState } from "./hooks/use-hydrate-state";
import { initialUserState, UserState } from "./state/user.reducer";

const { REACT_APP_API_URL: API_URL, REACT_APP_API_VERSION: API_VERSION } = process.env;
axios.defaults.baseURL = `${API_URL}/${API_VERSION}`;

type Props = {
    state: State;
    dispatch: Dispatch<Actions>;
};

export const App: React.FC<Props> = ({ state, dispatch }): JSX.Element => {
    const { ui, isLoading, error, auth, user, ...dataState } = state;

    const [localData, setLocalData] = useLocalStorage<LocalDataState>("appData", combinedInitialDataState);

    const [localUser, setLocalUser] = useLocalStorage<UserState>("user", initialUserState);

    const [localAuth] = useLocalStorage<AuthState>("auth", initialAuthState);

    useEffect(() => {
        if (state.activeOrganisation) {
            axios.defaults.headers.common["x-tagesessen-organisation-id"] = state.activeOrganisation?._id;
        }
    }, [state.activeOrganisation]);

    useEffect(() => {
        setLocalData(dataState);
    }, [JSON.stringify(dataState)]);

    useEffect(() => {
        // Login
        if (user) {
            setLocalUser(user);
        }
    }, [JSON.stringify(user)]);

    useHydrateState(state, dispatch);

    // place new authToken in Authorization Header
    useAxiosSetup(state);

    return (
        <AuthContext.Provider value={{ auth: localAuth, user: localUser }}>
            <DataContext.Provider value={{ ...localData }}>
                <UiContext.Provider value={{ ...ui }}>
                    <ToastArea toasts={state.ui.toastMessages} dispatch={dispatch} />
                    <Switch>
                        <Route path="/reset-password">
                            <>
                                <PasswordReset />
                                <Footer />
                            </>
                        </Route>
                        <Route path="/activation-failed">
                            <>
                                <ActivationFailed />
                                <Footer />
                            </>
                        </Route>
                        <Route path="/login">
                            <>
                                <SignUpLoginPage />
                                <Footer />
                            </>
                        </Route>
                        <Route path="/impressum">
                            <Layout>
                                <Impressum />
                                <Footer />
                            </Layout>
                        </Route>
                        <Route path="/agb">
                            <Layout>
                                <AGB />
                                <Footer />
                            </Layout>
                        </Route>
                        <Route path="/datenschutz">
                            <Layout>
                                <DSE />
                                <Footer />
                            </Layout>
                        </Route>
                        <ProtectedRoutes state={state} />
                    </Switch>
                </UiContext.Provider>
            </DataContext.Provider>
        </AuthContext.Provider>
    );
};
