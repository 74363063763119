// import { useContext } from "react";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
// import { LoadingActions } from "../state/loading.reducer";
// import { DispatchContext } from "../context";
import { Response } from "./useUser.repository";

export const useRequest =
    (/* dispatch: Dispatch<{ type: LoadingActions | ErrorActions; payload: LoadingState | ErrorState }> */) => {
        // const { dispatch } = useContext(DispatchContext);

        const request = async <T>(request: AxiosRequestConfig): Promise<Response<T>> => {
            try {
                // error handling in interceptor in setup-token-refresh.ts
                const response = await axios({
                    withCredentials: true,
                    ...request,
                });

                return { status: response.status, data: response.data };
            } catch (err: any) {
                return { status: err.status, data: err.data };
            }
        };

        return { request };
    };
