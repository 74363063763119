import React, { useState } from "react";
import { Card } from "primereact/card";
import { TabView, TabPanel } from "primereact/tabview";
import { Message } from "primereact/message";

import LoginForm from "./login-form";
import SignupForm from "./signup-form";
import ResetPasswordForm from "./request-password-reset-form";
import "./styles.css";
import { useQuery } from "../../hooks/use-query";

export const SignUpLoginPage = (): JSX.Element => {
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const query = useQuery();

    return (
        <div className="signup-login-page p-d-flex p-jc-center p-ai-start p-mt-6">
            <Card className="p-col-12 p-md-8 p-lg-6 p-xl-5">
                <header className="p-d-flex p-flex-row p-flex-nowrap p-ai-center p-mb-4">
                    <div className="logo" />
                    <h1 className="p-mt-0 p-my-0 p-ml-2">
                        <a href="https://tagesessen.com" target="_blank">
                            tagesessen.com
                        </a>
                    </h1>
                </header>
                {query.get("logout") && (
                    <Message severity="info" text="Ihre Sitzung wurde beendet, bitte loggen Sie sich neu ein." />
                )}
                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                    <TabPanel header="Einloggen">
                        <LoginForm />
                    </TabPanel>
                    <TabPanel header="Registrieren">
                        <SignupForm />
                    </TabPanel>
                    <TabPanel header="Passwort vergessen">
                        <ResetPasswordForm />
                    </TabPanel>
                </TabView>
            </Card>
        </div>
    );
};
