import { Organisation } from "../entities/organisation.entity";
import { User } from "../entities/user.entity";
import { AuthActions } from "./auth.reducer";
import { UserActions } from "./user.reducer";

export enum OrganisationsActions {
    ALL_ORGANISATIONS_RECEIVED = "ALL_ORGANISATIONS_RECEIVED",
    ORGANISATION_RECEIVED = "ORGANISATION_RECEIVED",
    ORGANISATION_CREATED = "ORGANISATION_CREATED",
    ORGANISATION_UPDATED = "ORGANISATION_UPDATED",
    ORGANISATION_DELETED = "ORGANISATION_DELETED",
}

export type OrganisationsActionTypes =
    | { type: OrganisationsActions.ALL_ORGANISATIONS_RECEIVED; payload: Organisation[] }
    | { type: OrganisationsActions.ORGANISATION_RECEIVED; payload: Organisation }
    | { type: OrganisationsActions.ORGANISATION_CREATED; payload: Organisation }
    | { type: OrganisationsActions.ORGANISATION_UPDATED; payload: Organisation }
    | { type: OrganisationsActions.ORGANISATION_DELETED; payload: string }
    | { type: AuthActions.LOGOUT_USER }
    | { type: UserActions.SET_USER; payload: User };

export const initialOrganisationsState = [];

export const organisationsReducer = (state: Organisation[] = [], action: OrganisationsActionTypes): Organisation[] => {
    switch (action.type) {
        case OrganisationsActions.ALL_ORGANISATIONS_RECEIVED:
            return action.payload;

        case OrganisationsActions.ORGANISATION_CREATED:
            return [...state, action.payload];

        case OrganisationsActions.ORGANISATION_RECEIVED:
            return [...state.filter(({ _id }: { _id: string }): boolean => _id !== action.payload._id), action.payload];

        case OrganisationsActions.ORGANISATION_UPDATED:
            return state.map(
                (organisation: Organisation): Organisation => {
                    return organisation._id === action.payload._id ? action.payload : organisation;
                }
            );

        case OrganisationsActions.ORGANISATION_DELETED:
            return state.filter(({ _id }: { _id: string }): boolean => _id !== action.payload);

        case AuthActions.LOGOUT_USER:
            return initialOrganisationsState;

        default:
            return state;
    }
};
