import React from "react";
import { Route, Switch } from "react-router-dom";
import { UserProfile } from "./user-profile";

type Props = {
    mountPath?: string;
};

export const UserProfileRoutes: React.FC<Props> = ({ mountPath = "" }): JSX.Element => {
    return (
        <Route path={`${mountPath}/profile`}>
            <Switch>
                <Route path={`${mountPath}/profile`}>
                    <UserProfile />
                </Route>
            </Switch>
        </Route>
    );
};
