import React, { MouseEvent } from "react";
import axios from "axios";
import { UiActions, UiActionTypes } from "../state/ui.reducer";
import { makeToast } from "../utils/make-toast";

type Props = {
    email?: string;
    dispatch: (action: UiActionTypes) => void;
};

export const ConfirmAccount: React.FC<Props> = ({ email, dispatch }): JSX.Element => {
    async function resendActivationLink(e: MouseEvent) {
        e.preventDefault();
        try {
            const response = await axios({ url: `/auth/resend-activation`, method: "POST", data: { email } });

            if (response.status >= 400) {
                throw new Error();
            }

            dispatch({
                type: UiActions.ADD_TOAST_MESSAGE,
                payload: makeToast({
                    severity: "success",
                    summary: "E-Mail erfolgreich verschickt",
                    detail:
                        "Eine E-Mail mit einem neuen Aktivierungslink ist auf dem Weg zu Ihnen. Bitte checken Sie auch Ihren Spam-Ordner.",
                }),
            });
        } catch (error) {
            dispatch({
                type: UiActions.ADD_TOAST_MESSAGE,
                payload: makeToast({
                    summary: "Fehler",
                    detail: "Der Aktivierungslink konnte nicht versendet werden.",
                }),
            });
        }
    }

    return (
        <div className="">
            <h1>Bitte aktivieren Sie Ihren Account</h1>
            <p>
                Um Ihren Account nutzen zu können müssen Sie ihn zunächst bestätigen. Dazu haben wir Ihnen bei der
                Registrierung eine E-Mail mit einem Aktivierungslink geschickt. Bitte überprüfen Sie dazu auch Ihren
                Spam-Ordner.
            </p>
            <p>
                Sollten Sie die Mail nicht erhalten haben, können Sie sich den Link hier noch einmal zuschicken lassen.
            </p>
            <a href="/signup/resend" onClick={(e) => resendActivationLink(e)} className="button">
                Aktivierungslink erneut senden
            </a>
        </div>
    );
};
