import React from "react";
import "./styles.css";

type Props = {};

export const Impressum: React.FC<Props> = (props): JSX.Element => {
    return (
        <section className="legal">
            <h1>Impressum</h1>
            <h2>Betreiber-Information</h2>
            <p>
                SILVERDUST GmbH
                <br />
                Summer-Breeze-Weg 1<br />
                73453 Abtsgmuend
                <br />
                Germany
            </p>
            <p>
                Tel: +49 (0) 7366 – 92 984 00
                <br />
                Fax: +49 (0) 7366 – 92 984 10
            </p>

            <p>
                E-Mail: info@tagesessen.com
                <br />
                Internet: wwww.tagesessen.com
            </p>

            <p>
                Vertretungsberechtigter Geschäftsführer: Achim Ostertag
                <br />
                Registergericht: Amtsgericht Ulm
                <br />
                Registernummer: HRB 501425
                <br />
                Umsatzsteuer-Identifikationsnummer: DE 219 562 134
            </p>

            <h3>Bildnachweise</h3>
            <ul className="info-page__foto-credits">
                <li>Homepage: 123rf.com (Jacek-Chabraszewski)</li>
                <li>Info-Seite: 123rf.com (Petr Štpánek)</li>
            </ul>
        </section>
    );
};
