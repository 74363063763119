import React, { MouseEvent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { OrganisationForm } from "./organisation.form";
import { organisationValidationSchema } from "./organisation.validation";
import { useOrganisation } from "../../hooks/useOrganisation.repository";

import { organisationBlueprint } from "./organisation.blueprint";
import { OrganisationDto } from "../../entities/organisation.dto";

import { WithAuthorizationContext } from "../../components/hoc/with-authorization-context";
import { useQuery } from "../../hooks/use-query";
import { Authorized } from "../../access-control/authorized";

const OrganisationFormWithAuthorizationContext = WithAuthorizationContext(OrganisationForm);

export const OrganisationEdit: React.FC = (): JSX.Element => {
    const query = useQuery();
    const [organisationData, setOrganisationData] = useState<OrganisationDto>();
    const { organisationId } = useParams<{ organisationId: string }>();
    const { get, update, remove } = useOrganisation(organisationId);
    const history = useHistory();

    const returnTo = query.get("returnTo");

    useEffect(() => {
        (async () => {
            if (organisationId) {
                const organisation = await get(organisationId);
                if (organisation) {
                    setOrganisationData(organisation);
                }
            }
        })();
    }, [organisationId]);

    async function handleSubmit(values: OrganisationDto) {
        await update(values, organisationId!);

        history.push(returnTo ? returnTo : "/settings/organisations");
    }

    async function kill(e: MouseEvent<HTMLButtonElement>) {
        if (remove && window.confirm(`Wollen Sie ${organisationData?.name} wirklich löschen?`)) {
            await remove();
            history.push("/settings/organisations");
        }
    }

    return (
        <>
            <h1>Firma {organisationId ? "bearbeiten" : "anlegen"}</h1>
            <Authorized entityId={organisationId} message={<div>Zugriff nicht gestattet</div>}>
                <OrganisationFormWithAuthorizationContext
                    data={organisationData || organisationBlueprint}
                    validationSchema={organisationValidationSchema}
                    id={organisationId}
                    remove={kill}
                    handleSubmit={handleSubmit}
                    returnTo={returnTo}
                />
            </Authorized>
        </>
    );
};
