import React, { useContext, useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { DispatchContext, DataContext } from "../../context";
import { Organisation } from "../../entities/organisation.entity";
import { ActiveOrganisationActions } from "../../state/active-organisation.reducer";
import { useHistory } from "react-router-dom";
import { useLocalStorage } from "../../hooks/use-localstorage";

type Props = {};

export const OrganisationSwitch: React.FC<Props> = (props) => {
    const history = useHistory();
    const { dispatch } = useContext(DispatchContext);
    const { organisations, activeOrganisation } = useContext(DataContext);
    const [selectedActiveOrganisation, setSelectedActiveOrganisation] = useState<Organisation>();
    const [, setLocalActiveOrganisation] = useLocalStorage<Organisation | undefined>("activeOrganisation", undefined);

    useEffect(() => {
        if (activeOrganisation) {
            setSelectedActiveOrganisation(activeOrganisation);
        }
    }, [activeOrganisation]);

    useEffect(() => {
        if (organisations?.length === 1 && !activeOrganisation) {
            dispatch({ type: ActiveOrganisationActions.SET_ACTIVE_ORGANISATION, payload: organisations[0] });
        }
    }, [activeOrganisation, organisations]);

    function selectActiveOrganisation(event: { originalEvent: Event; value: string }) {
        const activeOrganisation = organisations?.find(
            (organisation: Organisation) => organisation._id === event.value
        );

        if (activeOrganisation) {
            setLocalActiveOrganisation(activeOrganisation);
            dispatch({ type: ActiveOrganisationActions.SET_ACTIVE_ORGANISATION, payload: activeOrganisation });
            history.push("/");
        }
    }

    const options = organisations?.map((organisation: Organisation) => ({
        label: organisation.name,
        value: organisation._id,
    }));

    return (
        <>
            {organisations && organisations?.length > 1
                ? options && (
                      <>
                          <Dropdown
                              value={selectedActiveOrganisation?._id}
                              placeholder="Firma auswählen"
                              options={options}
                              onChange={selectActiveOrganisation}
                          />
                      </>
                  )
                : selectedActiveOrganisation?.name}
        </>
    );
};
