import React from "react";
import "./styles.css";

type Props = {};

export const AGB: React.FC<Props> = (props): JSX.Element => {
    return (
        <section className="legal">
            <h1>Allgemeine Geschäftsbedingungen</h1>
            <ol className="legal__ol--nested">
                <li>
                    <strong>Betreiber, Gegenstand, Geltungsbereich</strong>
                    <ol>
                        <li>
                            Die vorliegenden Allgemeinen Geschäftsbedingungen (nachstehend „ AGB“) gelten fürdie Nutzung
                            des auf der Website www.tagesessen.com. betriebenen Online-Marktplatzes für Waren und
                            Dienstleistungen (nachstehend „Plattform“), auf dem Anbieter Anzeigen einstellen und
                            Interessenten diese einsehen können. Die AGB gelten sowohl für die Einstellung von Anzeigen
                            durch Anbieter als auch für die Nutzung als Interessent. Die Plattform wird betrieben von
                            SiLVERDUST GmbH, Summer-Breeze-Weg 1, 73453 Abtsgmuend.
                        </li>
                        <li>
                            Definitionen
                            <ol>
                                <li>
                                    Einträge in Dienstleisterverzeichnisse und/oder Anzeigenschaltungen auf der
                                    Plattform werden nachstehend als „Anzeigen“ bezeichnet.
                                </li>
                                <li>
                                    Auf der Plattform werbende Unternehmen werden nachstehend als „ Anbieter“
                                    bezeichnet.
                                </li>
                                <li>
                                    Mitteilungen, Bewertungen oder sonstige Inhalte, die Nutzer auf oder über die
                                    Plattform kommunizieren, werden als „ Nutzerbeiträge“ bezeichnet.
                                </li>
                            </ol>
                        </li>
                        <li>
                            Die Schaltung von Anzeigen auf der Plattform ist – wenn und soweit auf der Plattform
                            angegeben - kostenpflichtig. Die sonstige Nutzung der Plattform, insbesondere als
                            Interessent, ist kostenfrei.
                        </li>
                    </ol>
                </li>
                <li>
                    <strong>Anforderungen an Anzeigen</strong>
                    <ol>
                        <li>Anbieter hat seine Anzeigen inhaltlich zutreffend und jederzeitaktuell zu halten. </li>
                        <li>
                            Enthalten Anzeigen des Anbieters Hyperlinks, so hat er die technischeVerfügbarkeit der
                            Zielseite sicherzustellen, außerdem die inhaltliche Rechtmäßigkeit der Zielseite und des
                            Umfelds der Zielseite.
                        </li>
                        <li>
                            Der Anbieter hat dafür Sorge zu tragen, dass seine Anzeigennicht gegen einschlägige
                            gesetzliche Bestimmungen verstoßen. Dazu zählen beispielsweise das Verbot der unlauteren,
                            irreführenden oder sonst wettbewerbswidrigen Werbung nach UWG, die Preisangabenverordnung
                            oder StGB.
                        </li>
                        <li>
                            Die Anzeige darf nicht gegen Schutzrechte Dritter oder Rechte Dritter an geistigem Eigentum
                            verstoßen wie bspw. Namensrechte, Kennzeichenrechte (Marken, Geschmacksmuster) oder
                            Urheberrechte sowie sonstiger Rechte Dritter. Der Anbieter sichert zu, dass er über die für
                            die Schaltung seiner Anzeige erforderlichen Rechte an den Inhalten seiner Anzeige frei
                            verfügen kann und Rechte Dritter nicht entgegenstehen.
                        </li>
                    </ol>
                </li>
                <li>
                    <strong>Anforderungen an Nutzerbeiträge</strong>
                    <ol>
                        <li>
                            Es dürfen nur rechtmäßige Nutzerbeiträge (Mitteilungen, Bewertungen o.ä.) auf oderüber die
                            Plattform kommuniziert werden. Insbesondere dürfen die Nutzerbeiträge und/oder deren
                            Einstellung auf der Plattform keine Rechte Dritter (z.B. Namens-, Kennzeichen-, Urheber-,
                            Datenschutz-, Persönlichkeitsrechte usw.) verletzen. Der Nutzer sichert uns zu, dass er über
                            die erforderlichen Rechte für die Einstellung seiner Nutzerbeiträge auf der Plattform frei
                            verfügen kann und Rechte Dritter nicht entgegenstehen.
                        </li>
                        <li>
                            Die Nutzerbeiträge, ob in Bild oder Text, dürfen keine Gewaltdarstellungen beinhalten und
                            nicht sexuell anstößig sein. Sie dürfen keine diskriminierenden, beleidigenden,
                            rassistischen, verleumderischen oder sonst rechts- oder sittenwidrigen Aussagen oder
                            Darstellungen beinhalten
                        </li>
                        <li>
                            Bewertungen, die zu Anbietern abgegeben werden, dürfen keine unzutreffenden
                            Tatsachenbehauptungen oder Schmähkritik enthalten und nicht gegen Persönlichkeitsrechte
                            verstoßen.
                        </li>
                    </ol>
                </li>
                <li>
                    <strong>Sperrung von Anzeigen</strong>
                    <ol>
                        <li>
                            Es ist uns gestattet, Anzeigen sofort zu sperren, wenn Anhaltspunkte dafür vorliegen, dass
                            diese oder ggf. eine Zielseite, auf die von den Anzeigen weitergeleitet wird, oder das
                            Umfeld der Zielseite rechtswidrig ist oder Rechte Dritter verletzt. Als Anhaltspunktfür eine
                            Rechtswidrigkeit oder Rechtsverletzung ist es für diese Zwecke unter anderem anzusehen, wenn
                            Dritte Maßnahmen, gleich welcher Art, gegen uns oder gegen Sie ergreifen und diese Maßnahmen
                            auf den Vorwurf einer Rechtswidrigkeit und/oder Rechtsverletzung stützen. Die Unterbrechung
                            der Schaltung ist aufzuheben, sobald der Verdacht der Rechtswidrigkeit bzw. der
                            Rechtsverletzung ausgeräumt ist.
                        </li>
                        <li>
                            Wir unterrichten Sie unverzüglich über eine Sperrung von Anzeigen und fordern Sie unter
                            Bestimmung einer angemessenen Frist zur Ausräumung des Vorwurfs auf. Nach fruchtlosem
                            Fristablauf steht uns ein sofortiges Kündigungsrecht zu.
                        </li>
                    </ol>
                </li>
                <li>
                    <strong>Sperrung von Nutzerbeiträgen</strong>
                    <ol>
                        <li>Wir sind berechtigt, Nutzerbeiträge jederzeit zu sperren und/ oder zu löschen. </li>
                    </ol>
                </li>
                <li>
                    <strong>Haftungsausschlüsse und -beschränkungen</strong>
                    <p>Für eine Haftung von uns auf Schadensersatz gilt:</p>
                    <ol>
                        <li>
                            Bei Vorsatz und grober Fahrlässigkeit, auch unserer Erfüllungsgehilfen, haften wir nach den
                            gesetzlichen Bestimmungen. Das gleiche gilt bei fahrlässig verursachten Schäden aus der
                            Verletzung des Lebens, des Körpers oder der Gesundheit.
                        </li>
                        <li>
                            Bei fahrlässig verursachten Sach- und Vermögensschäden haften wir nur bei der Verletzung
                            einer wesentlichen Vertragspflicht, jedoch der Höhe nach beschränkt auf diebei
                            Vertragsschluss vorhersehbaren und vertragstypischen Schäden; wesentliche Vertragspflichten
                            sind solche, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst
                            ermöglicht und auf deren Einhaltung der Vertragspartner regelmäßig vertrauen darf.
                        </li>
                        <li>Im Übrigen ist eine Haftung von uns, unabhängig von deren Rechtsgrund, ausgeschlossen. </li>
                        <li>
                            Die Haftungsausschlüsse und -beschränkungen der vorstehenden Absätze (1) bis (3) gelten
                            sinngemäß auch zugunsten unserer Erfüllungsgehilfen.
                        </li>
                        <li>
                            Eine Haftung wegen Übernahme einer Garantie oder nach dem Produkthaftungsgesetz bleibt von
                            den Haftungsausschlüssen und -beschränkungen der vorstehenden Absätze(1) bis (4) unberührt.
                        </li>
                    </ol>
                </li>
                <li>
                    <strong>Sonstiges</strong>
                    <ol>
                        <li>Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts. </li>
                        <li>
                            Gerichtsstand im Verkehr mit Kaufleuten, juristischen Personen des öffentlichen Rechts oder
                            öffentlich-rechtlichen Sondervermögen ist der Sitz unseres Unternehmens. Wir sind jedoch
                            nach unserer Wahl berechtigt, am Sitz des Kunden zu klagen.
                        </li>
                    </ol>
                </li>
            </ol>
        </section>
    );
};
