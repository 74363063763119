import { useContext } from "react";
import { useRequest } from "./use-request";

import { DispatchContext } from "../context/dispatch.context";
import { Organisation } from "../entities/organisation.entity";
import { OrganisationDto } from "../entities/organisation.dto";

import { OrganisationsActions } from "../state/organisation.reducer";
import { UiActions } from "../state/ui.reducer";
import { Store } from "../entities/store.entity";
import { StoresActions } from "../state/stores.reducer";
import { makeToast } from "../utils/make-toast";

type ReturnType = {
    create: (data: OrganisationDto) => void;
    getAll: () => Promise<void>;
    getStores: () => Promise<void>;
    get: (organisationId: Organisation["_id"]) => Promise<Organisation | void>;
    update: (data: OrganisationDto, id: Organisation["_id"]) => void;
    remove: () => void;
    download: (searchPhrase: string, fileName: string) => void;
};

export const useOrganisation = (id?: Organisation["_id"]): ReturnType => {
    const { dispatch } = useContext(DispatchContext);

    const { request } = useRequest();

    const getAll = async (): Promise<void> => {
        const response = await request<Organisation[]>({
            url: `/organisations/?populate=image,stores`,
        });

        if (response.status < 400) {
            dispatch({
                type: OrganisationsActions.ALL_ORGANISATIONS_RECEIVED,
                payload: response.data,
            });
        }

        // if (response.status >= 400) {
        //     dispatch({
        //         type: UiActions.ADD_TOAST_MESSAGE,
        //         payload: {
        //             severity: "error",
        //             summary: "Fehler",
        //             detail: "Stammdaten konnten nicht geladen werden",
        //             life: 5000,
        //         },
        //     });
        // } else {
        //     dispatch({
        //         type: OrganisationsActions.ALL_ORGANISATIONS_RECEIVED,
        //         payload: response.data,
        //     });
        // }
    };

    const getStores = async (): Promise<void> => {
        if (id) {
            const response = await request<Store[]>({
                url: `/organisations/${id}/stores?populate=images`,
            });

            if (response.status >= 400) {
                dispatch({
                    type: UiActions.ADD_TOAST_MESSAGE,
                    payload: makeToast({
                        severity: "error",
                        summary: "Fehler",
                        detail: "Filialen konnten nicht geladen werden",
                    }),
                });
            } else {
                dispatch({
                    type: StoresActions.STORES_RECEIVED,
                    payload: { id: id, stores: response.data },
                });
            }
        }
    };

    const create = async (data: OrganisationDto): Promise<void> => {
        const response = await request<Organisation>({
            url: `/organisations`,
            method: "POST",
            data,
        });

        if (response.status >= 400) {
            dispatch({
                type: UiActions.ADD_TOAST_MESSAGE,
                payload: makeToast({
                    severity: "error",
                    summary: "Fehler",
                    detail: "Stammdaten konnten nicht gespeichert werden",
                }),
            });
        } else {
            dispatch({
                type: OrganisationsActions.ORGANISATION_CREATED,
                payload: response.data,
            });
        }
    };

    const get = async (organisationId: Organisation["_id"]): Promise<Organisation | void> => {
        const response = await request<Organisation>({
            url: `/organisations/${organisationId}?populate=image`,
        });

        if (response.status >= 400) {
            dispatch({
                type: UiActions.ADD_TOAST_MESSAGE,
                payload: makeToast({
                    severity: "error",
                    summary: "Fehler",
                    detail: "Stammdaten konnten nicht geladen werden",
                }),
            });
        } else {
            return response.data;
        }
    };

    const update = async (data: OrganisationDto, id: Organisation["_id"]): Promise<void> => {
        const response = await request<Organisation>({ url: `/organisations/${id}`, method: "PATCH", data });

        if (response.status >= 400) {
            dispatch({
                type: UiActions.ADD_TOAST_MESSAGE,
                payload: makeToast({
                    severity: "error",
                    summary: "Fehler",
                    detail: "Stammdaten konnten nicht aktualisiert werden",
                }),
            });
        } else {
            dispatch({
                type: OrganisationsActions.ORGANISATION_UPDATED,
                payload: response.data,
            });
        }
    };

    const remove = async () => {
        await request<undefined>({ url: `/organisations/${id}`, method: "DELETE" });
        dispatch({ type: OrganisationsActions.ORGANISATION_DELETED, payload: id! });
    };

    const download = async (searchPhrase: string, fileName: string) => {
        const response = await request<Organisation>({
            url: `/organisations/download?search=${searchPhrase}&populate=stores`,
            responseType: "blob",
        });

        let objectUrl = window.URL.createObjectURL(response.data);

        const anchor = document.createElement("a");
        anchor.href = objectUrl;
        anchor.download = fileName;
        anchor.click();

        window.URL.revokeObjectURL(objectUrl);
    };

    return { getAll, getStores, create, get, update, remove, download };
};
