import { Store } from "../entities/store.entity";

export enum StoresActions {
    STORES_RECEIVED = "STORES_RECEIVED",
    STORE_CREATED = "STORE_CREATED",
    STORE_UPDATED = "STORE_UPDATED",
    STORE_DELETED = "STORE_DELETED",
}

export type StoreState = { [index: string]: Store[] } | undefined;

export type StoresActionTypes =
    | { type: StoresActions.STORES_RECEIVED; payload: { id: string; stores: Store[] } }
    | { type: StoresActions.STORE_CREATED; payload: { id: string; store: Store } }
    | { type: StoresActions.STORE_UPDATED; payload: { id: string; store: Store } }
    | { type: StoresActions.STORE_DELETED; payload: { id: string; storeId: string } };

export const initialStoresState = undefined;

export const storesReducer = (state: StoreState = undefined, action: StoresActionTypes): StoreState => {
    switch (action.type) {
        case StoresActions.STORES_RECEIVED:
            return {
                ...state,
                [action.payload.id]: action.payload.stores,
            };

        case StoresActions.STORE_CREATED:
            const organisationId = action.payload.id;
            const newStore = action.payload.store;

            return {
                ...state,
                [organisationId]: state && state[organisationId] ? [...state[organisationId], newStore] : [newStore],
            };

        case StoresActions.STORE_UPDATED:
            return {
                ...state,
                [action.payload.id]:
                    state && state[action.payload.id]
                        ? state[action.payload.id].map((store: Store) =>
                              store._id === action.payload.store._id ? action.payload.store : store
                          )
                        : [action.payload.store],
            };

        case StoresActions.STORE_DELETED:
            return {
                ...state,
                [action.payload.id]:
                    state && state[action.payload.id]
                        ? state[action.payload.id].filter((store: Store) => store._id !== action.payload.storeId)
                        : state![action.payload.id],
            };

        default:
            return state;
    }
};
