import React, { ChangeEvent, FormEvent, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useUser } from "../../hooks/useUser.repository";
import { useHistory } from "react-router-dom";

const LoginForm: React.FC = (): JSX.Element => {
    const [email, setEmail] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [password, setPassword] = useState<string>("");
    const history = useHistory();

    const { login } = useUser();

    async function loginUser(event: FormEvent) {
        event.preventDefault();
        setLoading(true);
        await login(email, password);
        history.push("/");
    }

    return (
        <div className="p-fluid">
            <form onSubmit={loginUser}>
                <div className="p-field">
                    <label htmlFor="email">E-Mail</label>
                    <InputText
                        name="email"
                        id="email"
                        type="email"
                        value={email}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                    />
                </div>
                <div className="p-field">
                    <label htmlFor="password">Passwort</label>
                    <InputText
                        name="password"
                        id="password"
                        type="password"
                        value={password}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                    />
                </div>
                <div className="p-field p-d-inline-flex">
                    <Button disabled={loading}>{loading ? "Sie werden eingeloggt..." : "Einloggen"}</Button>
                </div>
            </form>
        </div>
    );
};

export default LoginForm;
