import React from "react";
import { Offer } from "../../entities/offer.entity";
import { Store } from "../../entities/store.entity";
import { Image } from "../../entities/image.entity";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { PaginatedResult } from "../../entities/paginated-result";

const { REACT_APP_API_URL: API_URL, REACT_APP_APP_URL: APP_URL } = process.env;

type Props = {
    offers: Offer[];
    store: Store | undefined;
    dataTableRef: React.Ref<DataTable>;
    actionButtons: (offer: Offer) => JSX.Element;
    heading: String;
    pagingInfo: PaginatedResult<Offer>;
    onPage: (event: { first: number; rows: number; page: number }) => void;
};

export const OfferTable: React.FC<Props> = ({
    offers,
    actionButtons,
    dataTableRef,
    store,
    heading,
    pagingInfo,
    onPage,
}): JSX.Element => {
    const { page, limit, totalDocs, hasNextPage, hasPrevPage } = pagingInfo;
    const first = page * limit - 1;
    return (
        <DataTable
            ref={dataTableRef}
            value={offers}
            dataKey="_id"
            paginator={hasNextPage || hasPrevPage}
            lazy
            first={first}
            rows={limit}
            totalRecords={totalDocs}
            onPage={onPage}
            header={
                <div className="p-d-flex p-jc-between p-ai-center">
                    <div className="table-heading">
                        {/* <span>{store && store.name}</span> */}
                        <span>{heading}</span>
                    </div>
                    {store && (
                        <a href={`${APP_URL}/gastro/${store?.slug}`} className="datatable__store-link" target="_blank">
                            Filialseite ansehen <i className="pi pi-arrow-right" style={{ fontSize: ".8em" }}></i>
                        </a>
                    )}
                </div>
            }
            className="p-datatable-responsive"
        >
            <Column field="name" header="Titel"></Column>
            <Column
                field="image"
                header="Bild"
                body={(offer: Offer): JSX.Element | null => {
                    const image = offer.image as Image;
                    return image ? (
                        <img
                            alt={image.caption}
                            className="offer-item__image"
                            src={`${API_URL}/${image?.renditions.square.path}`}
                        />
                    ) : null;
                }}
            ></Column>
            <Column
                field="price"
                header="Preis"
                body={(offer: Offer): string =>
                    offer.price ? offer.price.toLocaleString("de-DE", { style: "currency", currency: "EUR" }) : "--"
                }
            ></Column>
            <Column
                field="date"
                header="Angebot am"
                body={(offer: Offer): string =>
                    offer.date ? new Date(offer.date).toLocaleDateString() : "Speisekarte"
                }
            ></Column>
            <Column body={actionButtons} header="Aktionen"></Column>
        </DataTable>
    );
};
