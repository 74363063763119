import { ToastMessage } from "primereact/toast";

export const makeToast = ({
    severity = "error",
    sticky = true,
    summary,
    detail,
    life = 8000,
}: ToastMessage): ToastMessage => ({
    sticky,
    severity,
    summary,
    detail,
    life,
});
