import React, { MouseEvent, useContext } from "react";
import { useHistory } from "react-router-dom";
import { DataContext, DispatchContext } from "../../context";
import { Organisation } from "../../entities/organisation.entity";
import { useLocalStorage } from "../../hooks/use-localstorage";
import { ActiveOrganisationActions } from "../../state/active-organisation.reducer";

type Props = {
    redirectTarget?: string;
};

export const OrganisationChoice: React.FC<Props> = ({ redirectTarget = "/" }): JSX.Element => {
    const history = useHistory();
    const { dispatch } = useContext(DispatchContext);
    const { organisations } = useContext(DataContext);
    const [, setLocalActiveOrganisation] = useLocalStorage<Organisation | undefined>("activeOrganisation", undefined);

    function choose(e: MouseEvent<HTMLAnchorElement>, organisation: Organisation) {
        e.preventDefault();
        setLocalActiveOrganisation(organisation);
        dispatch({ type: ActiveOrganisationActions.SET_ACTIVE_ORGANISATION, payload: organisation });
        redirectTarget && history.push(redirectTarget);
    }

    return (
        <>
            {organisations && organisations.length > 1 ? (
                <div className="p-listbox p-component">
                    <div className="p-listbox-list-wrapper">
                        <ul className="p-listbox-list" role="listbox" aria-multiselectable="false">
                            {organisations?.map((organisation: Organisation) => (
                                <li key={organisation._id} className="p-listbox-item">
                                    <a
                                        href="#1"
                                        onClick={(e: MouseEvent<HTMLAnchorElement>) => choose(e, organisation)}
                                    >
                                        {organisation.name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            ) : null}
        </>
    );
};
