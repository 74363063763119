import React, { useContext } from "react";

import { AuthContext, DataContext, DispatchContext } from "../../context";
import { StoreIndex } from "../stores/store.index";
import { Onboarding } from "./onboarding";
import { ConfirmAccount } from "../../components/confirm-account";
import { getStoresFromState } from "../stores/get-stores-from-state";
import { Spinner } from "../../components/";

import "./styles.css";

type Props = {
    loading: Boolean;
};

export const Dashboard: React.FC<Props> = ({ loading }): JSX.Element => {
    const { activeOrganisation, organisations, stores } = useContext(DataContext);
    const { dispatch } = useContext(DispatchContext);
    const { user } = useContext(AuthContext);

    if (!user?.isConfirmed) {
        return <ConfirmAccount email={user?.email} dispatch={dispatch} />;
    }

    const activeStores = getStoresFromState(activeOrganisation?._id, stores);

    return (
        <>
            {loading ? (
                <div className="p-d-flex p-jc-center">
                    <Spinner />
                </div>
            ) : (
                <>
                    {organisations && organisations.length > 0 && activeStores.length > 0 ? (
                        <StoreIndex />
                    ) : (
                        <Onboarding
                            organisation={activeOrganisation && activeOrganisation?._id}
                            store={activeStores.length > 0}
                        />
                    )}
                </>
            )}
        </>
    );
};
