import { useEffect } from "react";
import axios from "axios";
import { State } from "../state/root.reducer";

export const useAxiosSetup = (state: State): void => {
    useEffect(() => {
        if (state.auth?.token) {
            axios.defaults.headers.common["Authorization"] = `Bearer ${state.auth.token}`;
        }

        if (state.activeOrganisation) {
            axios.defaults.headers.common["x-tagesessen-organisation-id"] = state.activeOrganisation._id;
        }
    }, [state.auth?.token, state.activeOrganisation?._id]);
};
