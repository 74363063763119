import React, { useContext } from "react";
import { Button } from "primereact/button";

import { AuthContext, DispatchContext, UiContext } from "../../context/";
import { UiActions } from "../../state/ui.reducer";
import "./styles.css";

export const Header: React.FC = ({ children }): JSX.Element | null => {
    const { user } = useContext(AuthContext);
    const { dispatch } = useContext(DispatchContext);
    const { sidebarVisible } = useContext(UiContext);

    return (
        <header className="header p-px-2 p-px-lg-4">
            {user?._id ? (
                <Button
                    icon="pi pi-bars"
                    onClick={() => dispatch({ type: UiActions.SET_SIDEBAR_VISIBLE, payload: !sidebarVisible })}
                />
            ) : (
                <span></span>
            )}
            {children}
            {/* <Link to="/settings/profile">
                <i className="pi pi-user" style={{ fontSize: "1.5rem" }}></i>
            </Link> */}
            <span></span>
        </header>
    );
};
