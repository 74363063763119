import { ToastMessage } from "primereact/toast";
import { AuthActions, AuthActionTypes } from "./auth.reducer";

export enum UiActions {
    SET_SIDEBAR_VISIBLE = "SET_SIDEBAR_VISIBLE",
    ADD_TOAST_MESSAGE = "ADD_TOAST_MESSAGE",
    CLEAR_TOAST_MESAGES = "CLEAR_TOAST_MESAGES",
    LOGOUT_USER = "LOGOUT_USER",
}

export type UiActionTypes = { type: UiActions.SET_SIDEBAR_VISIBLE; payload: boolean } | { type: UiActions.ADD_TOAST_MESSAGE; payload: ToastMessage } | { type: UiActions.CLEAR_TOAST_MESAGES } | { type: UiActions.LOGOUT_USER };

export interface UiState {
    isLoading: boolean;
    sidebarVisible: boolean;
    toastMessages: ToastMessage[];
}

export const initialUiState = {
    isLoading: false,
    sidebarVisible: false,
    toastMessages: [],
};

export const uiReducer = (state: UiState, action: UiActionTypes | AuthActionTypes): UiState => {
    switch (action.type) {
        case UiActions.SET_SIDEBAR_VISIBLE:
            return { ...state, sidebarVisible: action.payload };

        case UiActions.ADD_TOAST_MESSAGE:
            return { ...state, toastMessages: [...state.toastMessages, action.payload] };

        case UiActions.CLEAR_TOAST_MESAGES:
            return { ...state, toastMessages: [] };

        case AuthActions.LOGOUT_USER:
        case UiActions.LOGOUT_USER:
            return { ...state, sidebarVisible: false };

        default:
            return state;
    }
};
